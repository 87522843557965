// extracted by mini-css-extract-plugin
export var h1Typography = "dashboard-module--h1-typography--aBiIW";
export var h2Typography = "dashboard-module--h2-typography--GQjA3";
export var h2TypographySecond = "dashboard-module--h2-typography-second--a6Lon";
export var h3Typography = "dashboard-module--h3-typography--vkp-g";
export var h3TypographyError = "dashboard-module--h3-typography-error--XSOWb";
export var h4Typography = "dashboard-module--h4-typography--GE3yT";
export var pTypography = "dashboard-module--p-typography--gie4w";
export var smallPTypography = "dashboard-module--small-p-typography--5GOa5";
export var sidebarTypography = "dashboard-module--sidebar-typography--nHP8k";
export var errorTypography = "dashboard-module--error-typography--W9eio";
export var captionTypography = "dashboard-module--caption-typography--aRweQ";
export var footerText = "dashboard-module--footer-text--a5g9w";
export var authMessageLabelTypography = "dashboard-module--auth-message-label-typography--nJQ-r";
export var authMessageTypography = "dashboard-module--auth-message-typography--2TwF3";
export var versionInfoTypography = "dashboard-module--version-info-typography---zyGQ";
export var itemHidden = "dashboard-module--item-hidden--WNnov";
export var ___gatsby = "dashboard-module--___gatsby--2iAPS";
export var gatsbyFocusWrapper = "dashboard-module--gatsby-focus-wrapper--xh3da";
export var headerContainer = "dashboard-module--header-container--VOE1F";
export var headerText = "dashboard-module--header-text--P9P1a";
export var mobileHeaderText = "dashboard-module--mobile-header-text--ifnNo";
export var noPadding = "dashboard-module--no-padding--vNbeg";
export var footerContainer = "dashboard-module--footer-container--ALOCD";
export var messageContainer = "dashboard-module--message-container--SKUBT";
export var messageText = "dashboard-module--message-text--oacxv";
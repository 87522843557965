import * as React from 'react';
import clsx from 'clsx';
import { animated, useSpring } from '@react-spring/web';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderRounded from '@mui/icons-material/FolderRounded';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import {
    unstable_useTreeItem2 as useTreeItem2,
    UseTreeItem2Parameters,
} from '@mui/x-tree-view/useTreeItem2';
import {
    TreeItem2Content,
    TreeItem2IconContainer,
    TreeItem2Label,
    TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { I18n } from 'react-redux-i18n';
import { styledTreeItemRoot, stylesRichTreeView } from '../../../styles/mui/optimisTheme';
import { inputBorderDisabled, primaryColorHover, secondaryColor, secondaryColorSelected } from '../../../styles/mui/theme';
import { useCallback } from 'react';

type FileType = 'image' | 'pdf' | 'doc' | 'video' | 'folder' | 'pinned' | 'trash';

type ExtendedTreeItemProps = {
    fileType?: FileType;
    id: string;
    label: string;
    fileURLToPath?: string;
};

const documentsList: TreeViewBaseItem<ExtendedTreeItemProps>[] = [
    {
        id: '1',
        label: I18n.t("HelpDocs.contractTitle"),
        children: [
            { id: '1_1', label: I18n.t("HelpDocs.contractName1"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.contractFileName1") },
            { id: '1_2', label: I18n.t("HelpDocs.contractName2"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.contractFileName2") },
            { id: '1_3', label: I18n.t("HelpDocs.contractName3"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.contractFileName3") },
            { id: '1_4', label: I18n.t("HelpDocs.contractName4"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.contractFileName4") },
        ],
    },
    {
        id: '2',
        label: I18n.t("HelpDocs.handbookTitle"),
        children: [
            { id: '2_1', label: I18n.t("HelpDocs.handbookName1"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.handbookFileName1") },
            { id: '2_2', label: I18n.t("HelpDocs.handbookName2"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.handbookFileName2") },
            { id: '2_3', label: I18n.t("HelpDocs.handbookName3"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.handbookFileName3") },
            { id: '2_4', label: I18n.t("HelpDocs.handbookName4"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.handbookFileName4") },
            { id: '2_5', label: I18n.t("HelpDocs.handbookName5"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.handbookFileName5") },
        ],
    },
    {
        id: '3',
        label: I18n.t("HelpDocs.modulesTitle"),
        children: [
            { id: '3_1', label: I18n.t("HelpDocs.modulesName1"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.modulesFileName1") },
            { id: '3_2', label: I18n.t("HelpDocs.modulesName2"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.modulesFileName2") },
            { id: '3_3', label: I18n.t("HelpDocs.modulesName3"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.modulesFileName3") },
            { id: '3_4', label: I18n.t("HelpDocs.modulesName4"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.modulesFileName4") },
            { id: '3_5', label: I18n.t("HelpDocs.modulesName5"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.modulesFileName5") },
            { id: '3_6', label: I18n.t("HelpDocs.modulesName6"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.modulesFileName6") },
            { id: '3_7', label: I18n.t("HelpDocs.modulesName7"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.modulesFileName7") },
            { id: '3_8', label: I18n.t("HelpDocs.modulesName8"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.modulesFileName8") },
            { id: '3_9', label: I18n.t("HelpDocs.modulesName9"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.modulesFileName9") },
            { id: '3_10', label: I18n.t("HelpDocs.modulesName10"), fileType: 'pdf', fileURLToPath: I18n.t("HelpDocs.modulesFileName10") },
        ],
    },
];

declare module 'react' {
    interface CSSProperties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    "flexDirection": "row-reverse",
    "borderRadius": theme.spacing(0.7),
    "marginBottom": theme.spacing(0.5),
    "marginTop": theme.spacing(0.5),
    "padding": theme.spacing(0.5),
    "paddingRight": theme.spacing(1),
    "fontWeight": 500,
    [`& .${treeItemClasses.iconContainer}`]: {
        marginRight: theme.spacing(2),
    },
    [`&.Mui-expanded `]: {
        '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
            backgroundColor: secondaryColor,
            color: primaryColorHover,
        },
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: '16px',
            top: '44px',
            height: 'calc(100% - 48px)',
            width: '1.5px',
            backgroundColor: inputBorderDisabled,
        },
    },
    '&:hover .labelIcon': {
        backgroundColor: "transparent",
        color: "inherit",
    },
    [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
        backgroundColor: secondaryColorSelected,
        color: primaryColorHover,
    },
}));

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
        },
    });

    return <AnimatedCollapse style={style} {...props} />;
}

interface CustomLabelProps {
    children: React.ReactNode;
    icon?: React.ElementType;
    expandable?: boolean;
}

function CustomLabel({
    icon: Icon,
    expandable,
    children,
    ...other
}: CustomLabelProps) {
    return (
        <TreeItem2Label
            {...other}
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {Icon && (
                <Box
                    component={Icon}
                    className="labelIcon"
                    color="inherit"
                    sx={{ mr: 1, fontSize: '1.2rem' }}
                />
            )}
            {children}
        </TreeItem2Label>
    );
}

const isExpandable = (reactChildren: React.ReactNode) => {
    if (Array.isArray(reactChildren)) {
        return reactChildren.length > 0 && reactChildren.some(isExpandable);
    }
    return Boolean(reactChildren);
};

const getIconFromFileType = (fileType: FileType) => {
    switch (fileType) {
        case 'image':
            return ImageIcon;
        case 'pdf':
            return PictureAsPdfIcon;
        case 'doc':
            return ArticleIcon;
        case 'video':
            return VideoCameraBackIcon;
        case 'folder':
            return FolderRounded;
        case 'pinned':
            return FolderOpenIcon;
        case 'trash':
            return DeleteIcon;
        default:
            return ArticleIcon;
    }
};

interface CustomTreeItemProps
    extends Omit<UseTreeItem2Parameters, 'rootRef'>,
    Omit<React.HTMLAttributes<HTMLLIElement>, 'onFocus'> { }

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
    props: CustomTreeItemProps,
    ref: React.Ref<HTMLLIElement>,
) {
    const { id, itemId, label, disabled, children, ...other } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
        publicAPI,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const item = publicAPI.getItem(itemId);
    const expandable = isExpandable(children);
    let icon;
    if (expandable) {
        icon = FolderRounded;
    } else if (item.fileType) {
        icon = getIconFromFileType(item.fileType);
    }

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root sx={styledTreeItemRoot} {...getRootProps(other)}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            'Mui-expanded': status.expanded,
                            'Mui-selected': status.selected,
                            'Mui-focused': status.focused,
                            'Mui-disabled': status.disabled,
                        }),
                    })}
                >
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>

                    <CustomLabel
                        {...getLabelProps({ icon, expandable: expandable && status.expanded })}
                    />
                </CustomTreeItemContent>
                {children && <TransitionComponent {...getGroupTransitionProps()} />}
            </TreeItem2Root>
        </TreeItem2Provider>
    );
});

export default function FileExplorer() {
    const handleSelectedItemsChange = (event: any, ids: any) => {
        const children = documentsList.find((elem) => elem.children && elem.children.find((item) => item.id === ids))?.children;
        if (children) {
            const urlPath = children.find((item) => item.id === ids)?.fileURLToPath;
            if (urlPath && window !== undefined) {
                const fileName = urlPath.split("/");
                const link = document.createElement('a');
                link.href = urlPath;
                link.setAttribute(
                    'download',
                    `${fileName[fileName.length - 1]}`,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                if (link.parentNode) {
                    link.parentNode.removeChild(link);
                }
            }
        }
    };
    return (
        <RichTreeView
            items={documentsList}
            aria-label="file explorer"
            sx={stylesRichTreeView}
            slots={{ item: CustomTreeItem }}
            onSelectedItemsChange={handleSelectedItemsChange}
        />
    );
}
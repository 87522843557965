// extracted by mini-css-extract-plugin
export var h1Typography = "baseTable-module--h1-typography--0CllC";
export var h2Typography = "baseTable-module--h2-typography--ulDHM";
export var mobileInfoHeaderBigger = "baseTable-module--mobile-info-header-bigger--4FjFR";
export var mobileInfoHeaderBiggerDisable = "baseTable-module--mobile-info-header-bigger-disable--BHbST";
export var h2TypographySecond = "baseTable-module--h2-typography-second--sKjq5";
export var h3Typography = "baseTable-module--h3-typography--8a53G";
export var mobileInfoHeader = "baseTable-module--mobile-info-header--x1R0F";
export var h3TypographyError = "baseTable-module--h3-typography-error--vRyWB";
export var h4Typography = "baseTable-module--h4-typography--IGEa1";
export var pTypography = "baseTable-module--p-typography--vnJYi";
export var mobileInfo = "baseTable-module--mobile-info--lVvo7";
export var name = "baseTable-module--name--+hYtz";
export var smallPTypography = "baseTable-module--small-p-typography--uqWYM";
export var sidebarTypography = "baseTable-module--sidebar-typography--xRMs6";
export var errorTypography = "baseTable-module--error-typography--WBeQk";
export var captionTypography = "baseTable-module--caption-typography--CkutU";
export var authMessageLabelTypography = "baseTable-module--auth-message-label-typography--SzhVP";
export var authMessageTypography = "baseTable-module--auth-message-typography--OPZ+e";
export var versionInfoTypography = "baseTable-module--version-info-typography--zAvu5";
export var itemHidden = "baseTable-module--item-hidden--xk4lx";
export var ___gatsby = "baseTable-module--___gatsby--L04If";
export var gatsbyFocusWrapper = "baseTable-module--gatsby-focus-wrapper--VLyKe";
export var mobileExamineeContainer = "baseTable-module--mobile-examinee-container--N2h9F";
export var mobileExamineeContainerDocument = "baseTable-module--mobile-examinee-container-document--t28Hq";
export var mobileExamineeContainerCheckbox = "baseTable-module--mobile-examinee-container-checkbox--q6pfG";
export var mobileExamineeContainerDirectory = "baseTable-module--mobile-examinee-container-directory--TPuTp";
export var mobileInfoContainer = "baseTable-module--mobile-info-container--P5kzp";
export var mobileInfoContainerDocument = "baseTable-module--mobile-info-container-document--zEHE7";
export var mobileInfoColumnsToLeftContainer = "baseTable-module--mobile-info-columns-to-left-container--NK2yY";
export var mobileColumn = "baseTable-module--mobile-column--9aud4";
export var mobileInfoColumnsContainer = "baseTable-module--mobile-info-columns-container--lKqdQ";
export var mobileInfoBold = "baseTable-module--mobile-info-bold--iQMYL";
export var mobileExamineeNameContainer = "baseTable-module--mobile-examinee-name-container---6iyt";
export var mobileExamineeIconContainer = "baseTable-module--mobile-examinee-icon-container--OKTLj";
export var mobileExamineeIconContainerArchive = "baseTable-module--mobile-examinee-icon-container-archive--wEVeK";
export var mobileExamineeIconContainerDocument = "baseTable-module--mobile-examinee-icon-container-document--dfDu6";
export var mobileExamineeIconContainerExamination = "baseTable-module--mobile-examinee-icon-container-examination--4wW2e";
export var mobileButtonContainer = "baseTable-module--mobile-button-container--qO7qa";
export var mobileFullButton = "baseTable-module--mobile-full-button--g7dna";
export var mobileButtonsContainer = "baseTable-module--mobile-buttons-container--yEK5D";
export var mobileOverwriteHeader = "baseTable-module--mobile-overwrite-header--3zmy5";
export var mobileColumnButtonContainer = "baseTable-module--mobile-column-button-container--TWMPO";
export var mobileBigIconButton = "baseTable-module--mobile-big-icon-button--xQJDh";
export var mobileJugmentHeaderContainer = "baseTable-module--mobile-jugment-header-container--TD-vO";
export var mobileJugmentHeaderIconContainer = "baseTable-module--mobile-jugment-header-icon-container--kYWqf";
export var mobileJugmentTitleContainer = "baseTable-module--mobile-jugment-title-container--oOTBV";
export var mobileJugmentIconContainer = "baseTable-module--mobile-jugment-icon-container--lU1OO";
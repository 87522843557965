// extracted by mini-css-extract-plugin
export var h1Typography = "editPsychLabLic-module--h1-typography--cZMdn";
export var h2Typography = "editPsychLabLic-module--h2-typography--AXFeB";
export var h2TypographySecond = "editPsychLabLic-module--h2-typography-second--qKsrb";
export var h3Typography = "editPsychLabLic-module--h3-typography--+4vMi";
export var h3TypographyError = "editPsychLabLic-module--h3-typography-error--IaFOR";
export var h4Typography = "editPsychLabLic-module--h4-typography--byYp2";
export var pTypography = "editPsychLabLic-module--p-typography--ZT4xT";
export var smallPTypography = "editPsychLabLic-module--small-p-typography--p5od-";
export var sidebarTypography = "editPsychLabLic-module--sidebar-typography--w4iBx";
export var errorTypography = "editPsychLabLic-module--error-typography--qwq1q";
export var captionTypography = "editPsychLabLic-module--caption-typography--48N2g";
export var authMessageLabelTypography = "editPsychLabLic-module--auth-message-label-typography--mkgcR";
export var authMessageTypography = "editPsychLabLic-module--auth-message-typography--G8AMv";
export var versionInfoTypography = "editPsychLabLic-module--version-info-typography--p3oj7";
export var itemHidden = "editPsychLabLic-module--item-hidden--uvKje";
export var ___gatsby = "editPsychLabLic-module--___gatsby--vcEOE";
export var gatsbyFocusWrapper = "editPsychLabLic-module--gatsby-focus-wrapper--9lJCd";
export var container = "editPsychLabLic-module--container--nz8f0";
export var headerContainer = "editPsychLabLic-module--header-container--NrVZ7";
export var contentContainer = "editPsychLabLic-module--content-container--8Hy8M";
export var singleCheckbox = "editPsychLabLic-module--single-checkbox--0ZKoV";
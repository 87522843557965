// extracted by mini-css-extract-plugin
export var h1Typography = "addPsychLabLic-module--h1-typography--xp0hR";
export var h2Typography = "addPsychLabLic-module--h2-typography--bVblD";
export var h2TypographySecond = "addPsychLabLic-module--h2-typography-second--z4ZIc";
export var h3Typography = "addPsychLabLic-module--h3-typography--BiCDd";
export var h3TypographyError = "addPsychLabLic-module--h3-typography-error--cBgkk";
export var h4Typography = "addPsychLabLic-module--h4-typography--+rkGk";
export var pTypography = "addPsychLabLic-module--p-typography--13v-s";
export var smallPTypography = "addPsychLabLic-module--small-p-typography--Bu+1O";
export var sidebarTypography = "addPsychLabLic-module--sidebar-typography--jLz5y";
export var errorTypography = "addPsychLabLic-module--error-typography--iQXrq";
export var captionTypography = "addPsychLabLic-module--caption-typography--yIolc";
export var authMessageLabelTypography = "addPsychLabLic-module--auth-message-label-typography--Odzl9";
export var authMessageTypography = "addPsychLabLic-module--auth-message-typography--oVdwb";
export var versionInfoTypography = "addPsychLabLic-module--version-info-typography--weFkg";
export var itemHidden = "addPsychLabLic-module--item-hidden--2yb8C";
export var ___gatsby = "addPsychLabLic-module--___gatsby--ElZ-l";
export var gatsbyFocusWrapper = "addPsychLabLic-module--gatsby-focus-wrapper--SNovZ";
export var container = "addPsychLabLic-module--container--OmL46";
export var headerContainer = "addPsychLabLic-module--header-container--mWaQk";
export var contentContainer = "addPsychLabLic-module--content-container--H2rZ4";
export var singleCheckbox = "addPsychLabLic-module--single-checkbox--9AU0U";
/* eslint-disable prefer-destructuring */

import React, { FC, useEffect, useRef } from "react";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Controller } from "react-hook-form";
import { FormInputOptionsProps, InputOptions } from "../../types";
import { muiStylesFormInputs } from "../muiStylesFormInputs";
import { getLableText } from "../../../DocumentationManagment/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const FormInputDropdown: FC<FormInputOptionsProps> = ({
  control,
  name,
  options,
  label,
  error,
  defaultValue,
  required,
  disabled,
  setSelectedValue,
  setValue,
  longInput,
  disableSorting,
}) => {

  const sortFn = (n1: InputOptions, n2: InputOptions) => {
    if (n1.label > n2.label) {
      return 1;
    }

    if (n1.label < n2.label) {
      return -1;
    }

    return 0;
  };

  const sortAndPrepareOptions = (opts: InputOptions[]): InputOptions[] => {
    const allItemsOption = opts.find(opt => opt.value === "ALL_ITEMS");
    const otherOptions = opts.filter(opt => opt.value !== "ALL_ITEMS");

    const sortedOtherOptions = disableSorting ? otherOptions : otherOptions.sort(sortFn);

    return allItemsOption
      ? [allItemsOption, ...sortedOtherOptions]
      : sortedOtherOptions;
  };

  const sortedArray = useRef<InputOptions[]>(sortAndPrepareOptions(options));

  const getCss = () => {
    let css = muiStylesFormInputs.inputText;
    if (longInput) {
      if (longInput === "short") {
        css = muiStylesFormInputs.inputTextShort;
      }
      if (longInput === "long") {
        css = muiStylesFormInputs.inputTextLongForm
      }
      if (longInput === "longer") {
        css = muiStylesFormInputs.inputTextLongerForm
      }
      if (longInput === "maxWidth") {
        css = muiStylesFormInputs.inputTextMaxWidth;
      }
    }

    return css;
  }

  const getMaxWidth = () => {
    let width = "250px";
    if (longInput) {
      if (longInput === "short") {
        width = muiStylesFormInputs.inputTextShort.width;
      }
      if (longInput === "long") {
        width = muiStylesFormInputs.inputTextLongForm.width;
      }
      if (longInput === "longer") {
        width = muiStylesFormInputs.inputTextLongerForm.width;
      }
      if (longInput === "maxWidth") {
        width = muiStylesFormInputs.inputTextMaxWidth.width;
      }
    }

    return width;
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (setSelectedValue !== undefined && setValue) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      setValue(name, setSelectedValue);
    }
  }, [setSelectedValue, setValue, name]);


  const generateOptions = () => {
    return sortedArray.current.map((option: InputOptions, index: number): JSX.Element => {
      const isAllItemsOption = option.value === "ALL_ITEMS";
      return (
        <MenuItem
          sx={muiStylesFormInputs.inputDropDownMenuItem}
          key={`dropdown-item-${option.value as string}`}
          value={option.value}
        >
          <Typography
            sx={{
              fontWeight: isAllItemsOption ? 'bold' : 'normal',
              whiteSpace: 'normal',
              wordBreak: 'break-word'
            }}
          >
            {option.label}
          </Typography>
        </MenuItem>
      );
    });
  };


  const setSelectedItemMenu = (value: any) => {
    let selValue = "";
    if (setSelectedValue && value === setSelectedValue) {
      selValue = setSelectedValue.toString();
    }

    return selValue;
  }

  return (
    <FormControl sx={muiStylesFormInputs.inputDropdown} error={error}>
      <InputLabel id="label-mf"
        sx={{
          backgroundColor: 'white',
          transform: 'translate(14px, -6px) scale(0.75)',
          padding: '0 4px'
        }}
      >
        {getLableText(label ?? "", required ?? false)}
      </InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select
            labelId="label-mf"
            label={getLableText(label ?? "", required ?? false)}
            disabled={disabled}
            value={value ?? ''}
            onChange={(event) => {
              const newValue = event.target.value;
              onChange(newValue);
              if (setValue) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                setValue(name, newValue === '' ? null : newValue);
              }
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              PaperProps: {
                sx: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  li: {
                    whiteSpace: "pre-wrap",
                    maxWidth: getMaxWidth(),
                  },
                  width: getMaxWidth(),
                },
              },
              variant: "menu",
            }}
            displayEmpty
            sx={[getCss(), value ? muiStylesFormInputs.inputDropdownSelect : muiStylesFormInputs.inputDropdownSelectNoValue]}
            endAdornment={
              value && (
                <IconButton
                  sx={{
                    visibility: value && !disabled ? "visible" : "hidden",
                  }}
                  onClick={(e) => onChange("")}
                >
                  <ClearIcon />
                </IconButton>
              )
            }
          >
            {generateOptions()}
          </Select>
        )}
        control={control}
        name={name}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};

export default FormInputDropdown;

// extracted by mini-css-extract-plugin
export var h1Typography = "examinationAssignment-module--h1-typography--tx3rC";
export var h2Typography = "examinationAssignment-module--h2-typography--3-fR5";
export var h2TypographySecond = "examinationAssignment-module--h2-typography-second--dson3";
export var h3Typography = "examinationAssignment-module--h3-typography--+6aRN";
export var legalBasisName = "examinationAssignment-module--legal-basis-name--E6LI8";
export var h3TypographyError = "examinationAssignment-module--h3-typography-error--zKHFK";
export var h4Typography = "examinationAssignment-module--h4-typography--UB5p3";
export var pTypography = "examinationAssignment-module--p-typography--GhfMV";
export var smallPTypography = "examinationAssignment-module--small-p-typography--4iYUR";
export var sidebarTypography = "examinationAssignment-module--sidebar-typography--1nIyF";
export var errorTypography = "examinationAssignment-module--error-typography--JJY9W";
export var captionTypography = "examinationAssignment-module--caption-typography--jCRY5";
export var authMessageLabelTypography = "examinationAssignment-module--auth-message-label-typography--KOnWU";
export var authMessageTypography = "examinationAssignment-module--auth-message-typography--zPKIH";
export var versionInfoTypography = "examinationAssignment-module--version-info-typography--glLal";
export var itemHidden = "examinationAssignment-module--item-hidden--aiOcc";
export var ___gatsby = "examinationAssignment-module--___gatsby--Hek2E";
export var gatsbyFocusWrapper = "examinationAssignment-module--gatsby-focus-wrapper--WJPX8";
export var breadcrumbsContainer = "examinationAssignment-module--breadcrumbs-container--avebV";
export var headerContainer = "examinationAssignment-module--header-container--9cL45";
export var subheaderContainer = "examinationAssignment-module--subheader-container--A-Exq";
export var bottomButtonsContainer = "examinationAssignment-module--bottom-buttons-container--NU5p+";
export var sectionHeader = "examinationAssignment-module--section-header--8bNCG";
export var legalBasisTableContainer = "examinationAssignment-module--legal-basis-table-container--BQVyb";
export var mobileLawBaseContainer = "examinationAssignment-module--mobile-law-base-container--xlUeE";
export var extended = "examinationAssignment-module--extended--qrozM";
export var mobileFadeOut = "examinationAssignment-module--mobile-fade-out--f13ry";
export var legalBasisHeader = "examinationAssignment-module--legal-basis-header--ZRW4h";
export var legalBaseIconContainer = "examinationAssignment-module--legal-base-icon-container--TveS9";
export var legalBaseContentContainer = "examinationAssignment-module--legal-base-content-container--42cVv";
export var methodologyContainer = "examinationAssignment-module--methodology-container--Xs5gy";
export var methodologyText = "examinationAssignment-module--methodology-text--rjjhM";
export var testContainer = "examinationAssignment-module--test-container--KQBzs";
export var testTypesContainer = "examinationAssignment-module--test-types-container--ohJ6N";
export var testColumnContainer = "examinationAssignment-module--test-column-container--SBvY+";
export var licenseWarning = "examinationAssignment-module--license-warning--syrDk";
export var infoContainer = "examinationAssignment-module--info-container--Z1Vf-";
export var testsMainContainer = "examinationAssignment-module--tests-main-container--g93Ra";
export var infoContainerMobile = "examinationAssignment-module--info-container-mobile--BdYK9";
export var buttonTestMobileContainer = "examinationAssignment-module--button-test-mobile-container--HAj8P";
export var headTitleCells = "examinationAssignment-module--head-title-cells--7HjXN";
export var buttonTestContainer = "examinationAssignment-module--button-test-container--19EZA";
export var buttonTestContainerHidden = "examinationAssignment-module--button-test-container-hidden--0xh7B";
// extracted by mini-css-extract-plugin
export var h1Typography = "addPsychLab-module--h1-typography--DDXMr";
export var h2Typography = "addPsychLab-module--h2-typography--+eg1l";
export var h2TypographySecond = "addPsychLab-module--h2-typography-second--uzuWG";
export var h3Typography = "addPsychLab-module--h3-typography--FDfGG";
export var h3TypographyError = "addPsychLab-module--h3-typography-error--iQeK5";
export var h4Typography = "addPsychLab-module--h4-typography--TEax-";
export var pTypography = "addPsychLab-module--p-typography--qC3dh";
export var smallPTypography = "addPsychLab-module--small-p-typography--4VxVn";
export var sidebarTypography = "addPsychLab-module--sidebar-typography--jJ9RO";
export var errorTypography = "addPsychLab-module--error-typography--UTZIj";
export var captionTypography = "addPsychLab-module--caption-typography--miAZj";
export var authMessageLabelTypography = "addPsychLab-module--auth-message-label-typography--DxveH";
export var authMessageTypography = "addPsychLab-module--auth-message-typography--geFs5";
export var versionInfoTypography = "addPsychLab-module--version-info-typography--qtYJ-";
export var itemHidden = "addPsychLab-module--item-hidden--tq3kN";
export var ___gatsby = "addPsychLab-module--___gatsby--0X17v";
export var gatsbyFocusWrapper = "addPsychLab-module--gatsby-focus-wrapper--5zhP2";
export var container = "addPsychLab-module--container--66cS1";
export var headerContainer = "addPsychLab-module--header-container--4s8MB";
export var contentContainer = "addPsychLab-module--content-container--EiNfT";
export var singleCheckbox = "addPsychLab-module--single-checkbox--y5OVH";
// extracted by mini-css-extract-plugin
export var h1Typography = "addPsychUser-module--h1-typography--WdeIE";
export var h2Typography = "addPsychUser-module--h2-typography--xejEI";
export var h2TypographySecond = "addPsychUser-module--h2-typography-second--hXthk";
export var h3Typography = "addPsychUser-module--h3-typography--QaAUL";
export var h3TypographyError = "addPsychUser-module--h3-typography-error--WA+Zu";
export var h4Typography = "addPsychUser-module--h4-typography--s4r4W";
export var pTypography = "addPsychUser-module--p-typography--IV0KU";
export var smallPTypography = "addPsychUser-module--small-p-typography--j8TNq";
export var sidebarTypography = "addPsychUser-module--sidebar-typography--Qu1pw";
export var errorTypography = "addPsychUser-module--error-typography--OGnac";
export var captionTypography = "addPsychUser-module--caption-typography--BfAuB";
export var authMessageLabelTypography = "addPsychUser-module--auth-message-label-typography--8vTL0";
export var authMessageTypography = "addPsychUser-module--auth-message-typography--F-+qc";
export var versionInfoTypography = "addPsychUser-module--version-info-typography--kzjNo";
export var itemHidden = "addPsychUser-module--item-hidden--rFB9g";
export var ___gatsby = "addPsychUser-module--___gatsby--G61nn";
export var gatsbyFocusWrapper = "addPsychUser-module--gatsby-focus-wrapper--g8bC0";
export var container = "addPsychUser-module--container--+ejhb";
export var headerContainer = "addPsychUser-module--header-container--JBeIs";
export var contentContainer = "addPsychUser-module--content-container--WPURV";
export var singleCheckbox = "addPsychUser-module--single-checkbox--cquos";
// extracted by mini-css-extract-plugin
export var h1Typography = "psychLabDataSection-module--h1-typography--Pvkwm";
export var h2Typography = "psychLabDataSection-module--h2-typography--67Vrt";
export var h2TypographySecond = "psychLabDataSection-module--h2-typography-second--wR+me";
export var h3Typography = "psychLabDataSection-module--h3-typography--bQHd6";
export var h3TypographyError = "psychLabDataSection-module--h3-typography-error--tKMIX";
export var h4Typography = "psychLabDataSection-module--h4-typography--1Nget";
export var pTypography = "psychLabDataSection-module--p-typography--QPhNf";
export var smallPTypography = "psychLabDataSection-module--small-p-typography--B6P3k";
export var sidebarTypography = "psychLabDataSection-module--sidebar-typography--e7++N";
export var errorTypography = "psychLabDataSection-module--error-typography--sNuWs";
export var captionTypography = "psychLabDataSection-module--caption-typography--IRPt-";
export var authMessageLabelTypography = "psychLabDataSection-module--auth-message-label-typography--wv-CJ";
export var authMessageTypography = "psychLabDataSection-module--auth-message-typography--9KM8C";
export var versionInfoTypography = "psychLabDataSection-module--version-info-typography--Q6rZE";
export var itemHidden = "psychLabDataSection-module--item-hidden--ydT0r";
export var ___gatsby = "psychLabDataSection-module--___gatsby--hu0EB";
export var gatsbyFocusWrapper = "psychLabDataSection-module--gatsby-focus-wrapper--7WVKc";
export var cardContentContainer = "psychLabDataSection-module--card-content-container--ZoGxY";
export var cardContentDataContainer = "psychLabDataSection-module--card-content-data-container--yfXZc";
export var cardDataColumn = "psychLabDataSection-module--card-data-column--HhZmd";
export var cardData = "psychLabDataSection-module--card-data--OxyOD";
export var cardDataRowName = "psychLabDataSection-module--card-data-row-name--eFdlk";
export var cardContentButtons = "psychLabDataSection-module--card-content-buttons--xUW-i";
// extracted by mini-css-extract-plugin
export var h1Typography = "examineeDataSection-module--h1-typography--+x0gp";
export var h2Typography = "examineeDataSection-module--h2-typography--768k6";
export var h2TypographySecond = "examineeDataSection-module--h2-typography-second--+L7YV";
export var h3Typography = "examineeDataSection-module--h3-typography--odlkd";
export var h3TypographyError = "examineeDataSection-module--h3-typography-error--pJDjo";
export var h4Typography = "examineeDataSection-module--h4-typography--wwdpC";
export var pTypography = "examineeDataSection-module--p-typography--Y1LsN";
export var smallPTypography = "examineeDataSection-module--small-p-typography--w+diq";
export var sidebarTypography = "examineeDataSection-module--sidebar-typography--kn3BD";
export var errorTypography = "examineeDataSection-module--error-typography--BXv-z";
export var captionTypography = "examineeDataSection-module--caption-typography--lbYoP";
export var authMessageLabelTypography = "examineeDataSection-module--auth-message-label-typography--PiQ5x";
export var authMessageTypography = "examineeDataSection-module--auth-message-typography--ZBydd";
export var versionInfoTypography = "examineeDataSection-module--version-info-typography--FyXoN";
export var itemHidden = "examineeDataSection-module--item-hidden--kDChn";
export var ___gatsby = "examineeDataSection-module--___gatsby--55UZB";
export var gatsbyFocusWrapper = "examineeDataSection-module--gatsby-focus-wrapper--mtOUh";
export var cardContentContainer = "examineeDataSection-module--card-content-container--qrYXU";
export var cardContentDataContainer = "examineeDataSection-module--card-content-data-container--uXwhG";
export var cardDataColumn = "examineeDataSection-module--card-data-column--D9T4X";
export var cardData = "examineeDataSection-module--card-data--b+bzV";
export var cardDataRowName = "examineeDataSection-module--card-data-row-name--Urb10";
export var cardContentButtons = "examineeDataSection-module--card-content-buttons--VLmMv";
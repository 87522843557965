// extracted by mini-css-extract-plugin
export var h1Typography = "loader-module--h1-typography--y6sQH";
export var h2Typography = "loader-module--h2-typography--rVCzw";
export var h2TypographySecond = "loader-module--h2-typography-second--IHgET";
export var h3Typography = "loader-module--h3-typography--esmnf";
export var h3TypographyError = "loader-module--h3-typography-error--Ip-Z0";
export var h4Typography = "loader-module--h4-typography--NZUqm";
export var pTypography = "loader-module--p-typography--pwcE9";
export var smallPTypography = "loader-module--small-p-typography--fCUlM";
export var sidebarTypography = "loader-module--sidebar-typography--0ieRG";
export var errorTypography = "loader-module--error-typography--4NGu6";
export var captionTypography = "loader-module--caption-typography--ILcYK";
export var authMessageLabelTypography = "loader-module--auth-message-label-typography---nbaB";
export var authMessageTypography = "loader-module--auth-message-typography--H3XZp";
export var versionInfoTypography = "loader-module--version-info-typography--+Cvu-";
export var itemHidden = "loader-module--item-hidden--DZyxz";
export var ___gatsby = "loader-module--___gatsby--QYWWZ";
export var gatsbyFocusWrapper = "loader-module--gatsby-focus-wrapper--0HmmQ";
export var container = "loader-module--container--jvfA8";
export var covering = "loader-module--covering--KXBvG";
export var loaderText = "loader-module--loader-text--6w1da";
export var hidden = "loader-module--hidden--3gjNm";
// extracted by mini-css-extract-plugin
export var h1Typography = "documentation-module--h1-typography--s71AB";
export var h2Typography = "documentation-module--h2-typography--3+GqN";
export var h2TypographySecond = "documentation-module--h2-typography-second--5vDVJ";
export var h3Typography = "documentation-module--h3-typography--NrGmq";
export var h3TypographyError = "documentation-module--h3-typography-error--D4S6i";
export var h4Typography = "documentation-module--h4-typography--+dALe";
export var pTypography = "documentation-module--p-typography--1wRX2";
export var smallPTypography = "documentation-module--small-p-typography--UTDv9";
export var sidebarTypography = "documentation-module--sidebar-typography--w67NK";
export var errorTypography = "documentation-module--error-typography--mOGuA";
export var captionTypography = "documentation-module--caption-typography--czH8y";
export var authMessageLabelTypography = "documentation-module--auth-message-label-typography--Zf0Vl";
export var authMessageTypography = "documentation-module--auth-message-typography--7uSFE";
export var versionInfoTypography = "documentation-module--version-info-typography--3PsbD";
export var itemHidden = "documentation-module--item-hidden--N0de1";
export var ___gatsby = "documentation-module--___gatsby--y2EQu";
export var gatsbyFocusWrapper = "documentation-module--gatsby-focus-wrapper--XXP-Y";
export var breadcrumbsContainer = "documentation-module--breadcrumbs-container--MZPp2";
export var headerContainer = "documentation-module--header-container--fJ-bw";
export var baseInfoContainer = "documentation-module--base-info-container--9qpW-";
export var reportInfoContainer = "documentation-module--report-info-container--K6O+q";
export var reportInfoContainerMobile = "documentation-module--report-info-container-mobile--wLZ+e";
export var reportFormContainer = "documentation-module--report-form-container--+QYye";
export var reportFormMobileContainer = "documentation-module--report-form-mobile-container--bpOMW";
export var reportDropdownColumn = "documentation-module--report-dropdown-column--we5mn";
export var reportMobileColumn = "documentation-module--report-mobile-column--Z5S9P";
export var reportFormButtons = "documentation-module--report-form-buttons--Q4wnF";
export var messageContainer = "documentation-module--message-container--kTWP8";
export var messageText = "documentation-module--message-text--3fziT";
export var consultationFieldsContainer = "documentation-module--consultation-fields-container--CisdG";
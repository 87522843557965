import React, { FC } from "react";
import * as styles from "../psychLab.module.scss";
import * as formStyles from "../../../Forms/forms.module.scss";
import Loader from "../../../Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import { selectSelectedPsychLabStatsLoading } from "../../../../redux/selectors/adminPanel/selectedPsychLabSelectors";
import FormDatePicker from "../../../Forms/Inputs/FormDatePicker";
import { I18n } from "react-redux-i18n";
import TertiaryButton from "../../../Common/Buttons/TertiaryButton";
import { useForm } from "react-hook-form";
import { StatsPsychLabData } from "../../../Forms/types";
import { fetchStatsList } from "../../../../redux/actions/adminPanel/selectedPsychLabActions";
import { convertToNativeDate } from "../../../Forms/utils";

const StatsSection: FC = () => {
  const dispatch = useDispatch();
  const { psychLabId } = useParams();
  const loading = useSelector(selectSelectedPsychLabStatsLoading);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
    defaultValues: {
      dateFrom: undefined,
      dateTo: undefined,
    },
  });

  const onSubmit = (data: StatsPsychLabData) => {
    const formattedData = {
      ...data,
      dateFrom: convertToNativeDate(data.dateFrom),
      dateTo: convertToNativeDate(data.dateTo),
    };

    dispatch(fetchStatsList({ labId: psychLabId, ...formattedData }));
  };

  return (
    <div className={styles.cardContentContainer}>
      <div className={styles.cardListContainer}>
        {loading ? (
          <div className={styles.messageContainer}>
            <Loader loading={loading} />
          </div>
        ) :
          <form onSubmit={handleSubmit(onSubmit)} className={formStyles.filtersContainer}>
            <div>
              <div className={formStyles.fieldsSectionContainer}>
                <div className={formStyles.fieldsContainer}>
                  <FormDatePicker
                    inputFormat={I18n.t("Format.ExamineeViewDateFormat")}
                    control={control}
                    name="dateFrom"
                    label={I18n.t("Forms.adminFields.dateFrom")}
                    setValue={setValue}
                    disableFuture
                  />
                  <FormDatePicker
                    inputFormat={I18n.t("Format.ExamineeViewDateFormat")}
                    control={control}
                    name="dateTo"
                    label={I18n.t("Forms.adminFields.dateTo")}
                    setValue={setValue}
                    disableFuture
                  />
                </div>
                <div className={formStyles.buttonsContainerMaxWide}>
                  <TertiaryButton isSubmit
                    text={I18n.t("Buttons.exportActivities")}
                  />
                </div>
              </div>
            </div>
          </form>
        }
      </div>
    </div>
  );
};

export default StatsSection;

// extracted by mini-css-extract-plugin
export var h1Typography = "pagination-module--h1-typography--U2oVS";
export var h2Typography = "pagination-module--h2-typography--kuY9c";
export var topPaginationContainer = "pagination-module--top-pagination-container--ywH7T";
export var h2TypographySecond = "pagination-module--h2-typography-second--RIjpC";
export var h3Typography = "pagination-module--h3-typography--Vqr25";
export var h3TypographyError = "pagination-module--h3-typography-error--ASvET";
export var h4Typography = "pagination-module--h4-typography--oIlSv";
export var pTypography = "pagination-module--p-typography--HHg7+";
export var smallPTypography = "pagination-module--small-p-typography--W1JIj";
export var sidebarTypography = "pagination-module--sidebar-typography--wDWsK";
export var errorTypography = "pagination-module--error-typography--ApeEa";
export var captionTypography = "pagination-module--caption-typography--tlEzY";
export var authMessageLabelTypography = "pagination-module--auth-message-label-typography--27N6U";
export var authMessageTypography = "pagination-module--auth-message-typography---OZyx";
export var versionInfoTypography = "pagination-module--version-info-typography--3AxZ9";
export var itemHidden = "pagination-module--item-hidden--bGqfT";
export var ___gatsby = "pagination-module--___gatsby--mtKBR";
export var gatsbyFocusWrapper = "pagination-module--gatsby-focus-wrapper--EjBXU";
export var buttonsContainer = "pagination-module--buttons-container--SUaEu";
export var iconButton = "pagination-module--icon-button--OpqiV";
export var next = "pagination-module--next--RTIcF";
export var previous = "pagination-module--previous--jNFw7";
export var disabled = "pagination-module--disabled--cF6LZ";
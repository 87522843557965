// extracted by mini-css-extract-plugin
export var h1Typography = "tabPanel-module--h1-typography--qnmi1";
export var h2Typography = "tabPanel-module--h2-typography--JflaW";
export var h2TypographySecond = "tabPanel-module--h2-typography-second--W3SPm";
export var h3Typography = "tabPanel-module--h3-typography--nZEVn";
export var h3TypographyError = "tabPanel-module--h3-typography-error--abJj7";
export var h4Typography = "tabPanel-module--h4-typography--qptFS";
export var pTypography = "tabPanel-module--p-typography--vabjA";
export var smallPTypography = "tabPanel-module--small-p-typography--88acT";
export var sidebarTypography = "tabPanel-module--sidebar-typography--dLL8q";
export var errorTypography = "tabPanel-module--error-typography--yNjRr";
export var captionTypography = "tabPanel-module--caption-typography--EmmiE";
export var authMessageLabelTypography = "tabPanel-module--auth-message-label-typography--pEqgp";
export var authMessageTypography = "tabPanel-module--auth-message-typography--VB9At";
export var versionInfoTypography = "tabPanel-module--version-info-typography--M3gX2";
export var itemHidden = "tabPanel-module--item-hidden--hPlwM";
export var ___gatsby = "tabPanel-module--___gatsby--yH493";
export var gatsbyFocusWrapper = "tabPanel-module--gatsby-focus-wrapper--Dsls0";
export var tabPanel = "tabPanel-module--tab-panel--JHv8w";
import * as styles from "../../judgment.module.scss";
import React, { FC, useEffect } from "react";
import dayjs from "dayjs";
import store from "../../../../../redux/store";
import { useParams } from "@reach/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useIsFirstRender } from "usehooks-ts";
import { updateJudgment } from "../../../../../redux/actions/documentation/judgmentActions";
import { selectJudgment } from "../../../../../redux/selectors/documentation/judgmentSelectors";
import { IsObjectEmpty } from "../../../../../utils";
import { handleNavigateToPreviousPage } from "../../../../../utils/paths";
import { dispatchNotification } from "../../../../../utils/redux";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../../Common/Buttons/SecondaryButton";
import FormDocumentExaminee from "../../../../Forms/FormDocumentExaminee";
import FormDatePicker from "../../../../Forms/Inputs/FormDatePicker";
import FormInputMultiRadio from "../../../../Forms/Inputs/FormInputMultiRadio";
import FormInputText from "../../../../Forms/Inputs/FormInputText";
import { InputOptions, MultiInputOptions } from "../../../../Forms/types";
import {
  schemaEmergencyVehicleDriverAnonymous,
  schemaEmergencyVehicleDriverPESEL,
  schemaEmergencyVehicleDriverWithoutPESEL,
} from "../../schema";
import { EmergencyVehicleDriverJudgmentData } from "../../types";
import { commonJudgmentDeafults } from "../../utils";
import FormInputMultiCheckbox from "../../../../Forms/Inputs/FormInputMultiCheckbox";
import FromInputCheckbox from "../../../../Forms/Inputs/FormInputCheckbox";
import { isFieldRequired, examineeRequiredFields } from "../../../ExaminationCard/utils";
import { selectSelectedExamineeData } from "../../../../../redux/selectors/examinees/selectedExamineeSelectors";

const EmergencyVehicleDriverJudgment: FC = () => {
  const isFirst = useIsFirstRender();
  const { examinationId, documentId } = useParams();

  const judgment = useSelector(
    selectJudgment
  ) as EmergencyVehicleDriverJudgmentData;
  const examinee = useSelector(selectSelectedExamineeData);

  const setExamineeUnder60 = () => {
    const birthDate = (examinee?.birthDate || "");
    let examineeUnder60yo = false;
    if (birthDate !== "") {
      const currentAgeExaminee = dayjs(judgment?.completionDate || "").diff(dayjs(examinee?.birthDate || ""), "year");
      examineeUnder60yo = currentAgeExaminee < 60;
    }

    return examineeUnder60yo;
  }

  const handleNextExaminationDate = () =>
  (setExamineeUnder60()
    ? dayjs(judgment?.completionDate).add(5, "year").subtract(1, "day").toISOString()
    : dayjs(judgment?.completionDate).add(30, "month").subtract(1, "day").toISOString());

  const handleInitialDrivingLicense = () => {
    const result = [];

    judgment?.forACategory && result.push(0);
    judgment?.forBCategory && result.push(1);
    judgment?.forCdCategories && result.push(2);

    return result;
  };

  const getSchema = (data: EmergencyVehicleDriverJudgmentData) => {
    let schema = null;

    if (data?.pesel) {
      schema = schemaEmergencyVehicleDriverPESEL;
    }
    if ((data?.pesel === null || data?.pesel === "") && data?.anonymous !== null && data.anonymous) {
      schema = schemaEmergencyVehicleDriverAnonymous;
    }

    return schema ?? schemaEmergencyVehicleDriverWithoutPESEL;
  }

  const methods = useForm<EmergencyVehicleDriverJudgmentData>({
    reValidateMode: "onChange",
    resolver: yupResolver(getSchema(judgment)),
    defaultValues: {
      ...commonJudgmentDeafults(judgment),
      isSuitable: judgment?.isSuitable,
      judgmentId: judgment?.judgmentId || "",
      nextExamination: judgment?.nextExamination || handleNextExaminationDate(),
      forACategory: judgment?.forACategory,
      forBCategory: judgment?.forBCategory,
      forCdCategories: judgment?.forCdCategories,
      drivingLicenses: handleInitialDrivingLicense().length === 0 ? undefined : handleInitialDrivingLicense(),
      isAppeal: judgment?.isAppeal,
      judgmentNo: judgment?.judgmentNo,
      completionDate: judgment?.completionDate || dayjs().toString(),
    },
  });

  const onSubmit = async (data: EmergencyVehicleDriverJudgmentData) => {
    const drivingLicenses = methods.getValues("drivingLicenses");

    data.forACategory = !!drivingLicenses?.includes(0);
    data.forBCategory = !!drivingLicenses?.includes(1);
    data.forCdCategories = !!drivingLicenses?.includes(2);

    await store
      .dispatch(
        updateJudgment({
          ...data,
          examinationId,
          documentId,
        })
      )
      .then(() => void handleNavigateToPreviousPage());
  };

  useEffect(() => {
    if (!isFirst && !IsObjectEmpty(methods.formState.errors)) {
      dispatchNotification("error", I18n.t("Forms.snackbar.required"));
    }
  }, [methods.formState.errors, isFirst]);

  const judgmentOptions: MultiInputOptions[] = [
    {
      label: I18n.t(
        "DocumentationManagement.judgment.emergencyVehicleDriver.true"
      ),
      value: true,
    },
    {
      label: I18n.t(
        "DocumentationManagement.judgment.emergencyVehicleDriver.false"
      ),
      value: false,
    },
  ];

  const drivingLicenseOptions: InputOptions[] = [
    { value: 0, label: "A1, A2, A" },
    { value: 1, label: "B1, B, B+E" },
    { value: 2, label: "C1, C1+E, C, C+E, D1, D1+E, D, D+E" },
  ];

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={styles.fieldsContainer}>
            <FromInputCheckbox
              name="isAppeal"
              control={methods.control}
              label={I18n.t("DocumentationManagement.judgment.isAppealTitle")} />
          </div>
          <h2>{I18n.t("DocumentationManagement.judgment.judgmentNumber")}</h2>
          <div className={styles.fieldsContainer}>
            <FormInputText
              control={methods.control}
              name="judgmentNo"
              required={isFieldRequired(getSchema(judgment), "judgmentNo")}
              label={I18n.t("Forms.fields.judgmentNumber")}
              error={!!methods.formState.errors.judgmentNo}
            />
          </div>
          <div className={styles.formContainer}>
            <FormDocumentExaminee requiredFields={examineeRequiredFields(getSchema(judgment))} initialData={judgment} />
          </div>
          <div className={styles.fieldJudgmentContainer}>
            <h2>{I18n.t("DocumentationManagement.judgment.statement")}</h2>
            <FormInputMultiRadio
              name="isSuitable"
              control={methods.control}
              options={judgmentOptions}
              error={!!methods.formState.errors.isSuitable?.message}
            />
            <h2>{I18n.t("DocumentationManagement.judgment.drivingLicense")}*</h2>
            <FormInputMultiCheckbox
              name="drivingLicenses"
              control={methods.control}
              options={drivingLicenseOptions}
              setValue={methods.setValue}
              defaultValue={methods.getValues("drivingLicenses")}
              row
              required={isFieldRequired(getSchema(judgment), "drivingLicenses")}
              error={!!(methods.formState.errors.drivingLicenses as any)?.message}
            />
          </div>
          <div className={styles.fieldsSectionContainer}>
            <div className={styles.fieldsContainer}>
              <FormDatePicker
                control={methods.control}
                name="nextExamination"
                label={I18n.t("Forms.fields.nextExamination")}
                error={!!methods.formState.errors.nextExamination}
              />
              <FormDatePicker
                control={methods.control}
                name="issueDate"
                label={I18n.t("Forms.fields.documentDate")}
                error={!!methods.formState.errors.issueDate}
              />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <SecondaryButton
              text={I18n.t("Buttons.cancel")}
              event={handleNavigateToPreviousPage}
            />
            <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default EmergencyVehicleDriverJudgment;

import * as styles from "./psychLabDataSection.module.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { I18n } from "react-redux-i18n";
import { PsychologicalLab } from "../../../../api/psychologicalLabApi";
import { FormatDate, emptyState } from "../../../../utils";
import { dashboardPath, handleNavigateToEditPsychLab, handleNavigateToPage, handleNavigateToPreviousPage } from "../../../../utils/paths";
import CrudButton from "../../../Common/Buttons/CrudButton";
import DeletePopup from "../../../Common/Popup/DeletePopup";
import { updateAdminLab } from "../../../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserId, selectUpdateAdminLab } from "../../../../redux/selectors/authSelectors";
import Loader from "../../../Common/Loader";
import InfoPopup from "../../../Common/Popup/InfoPopup";
import { deletePsychLab } from "../../../../redux/actions/adminPanel/psychologicalLabsActions";
import { selectPsychLabCUDRefreshNeeded } from "../../../../redux/selectors/adminPanel/psychologicalLabsSelectors";

interface Props {
  psychLab?: PsychologicalLab;
}

const PsychLabDataSection: FC<Props> = ({ psychLab }) => {
  const updateAdminLabState = useSelector(selectUpdateAdminLab);
  const adminId = useSelector(selectCurrentUserId);
  const [deletePopup, setDeletePopup] = useState(false);
  const [warningPopup, setWarningPopup] = useState(false);
  const [labToDelete, setLabToDelete] = useState<number | undefined>(undefined);
  const dispatch = useDispatch();
  const acceptButtonText = useRef<string>("");
  const contentText = useRef<string>("");
  const headerText = useRef<string>("");
  const refreshNeeded = useRef<boolean>(false);
  const refreshNeededState = useSelector(selectPsychLabCUDRefreshNeeded);

  const getClientStatus = (isActive: boolean) => {
    return isActive ? I18n.t("Forms.adminOptions.status.active") : I18n.t("Forms.adminOptions.status.disabled");
  }

  const loginToPsychLab = () => {
    dispatch(updateAdminLab({ userId: adminId ? adminId : 0, labId: psychLab ? psychLab.id : 0 }));
  }

  const handleDelete = () => {
    if (labToDelete) {
      dispatch(deletePsychLab(labToDelete));
      setDeletePopup(false);
      refreshNeeded.current = true;
    }
  };

  const renderPage = () => {
    return updateAdminLabState.loading ? <Loader loading /> : renderContent();
  }

  const renderContent = () => {
    return (
      <>
        <InfoPopup
          acceptButtonText={acceptButtonText.current}
          contentText={contentText.current}
          headerText={headerText.current}
          isOpen={warningPopup}
          onAcceptButton={() => setWarningPopup(false)}
        />
        <DeletePopup
          onCancelButton={() => setDeletePopup(false)}
          contentText={I18n.t("AdminDashboard.PsychLab.deletePopup.contentText")}
          headerText={I18n.t("AdminDashboard.PsychLab.deletePopup.headerText")}
          isOpen={deletePopup}
          onAcceptButton={() => handleDelete()}
          confirmationText={I18n.t(
            "AdminDashboard.PsychLab.deletePopup.confirmationText"
          )}
        />
        <div className={styles.cardContentContainer}>
          <div className={styles.cardContentButtons}>
            <CrudButton textId="loginPsychLab" type="login" event={() => { psychLab ? loginToPsychLab() : null; }} />
            <CrudButton
              textId="editPsychLab"
              type="edit"
              event={() => handleNavigateToEditPsychLab(psychLab ? psychLab.id : 0)}
            />
            <CrudButton
              textId="deletePsychLab"
              type="delete"
              event={() => {
                setLabToDelete(psychLab ? psychLab.id : 0);
                setDeletePopup(true);
              }} />
          </div>
          <div className={styles.cardContentDataContainer}>
            <div className={styles.cardDataColumn}>
              <div className={styles.cardDataRowName}>
                <p>{I18n.t("Forms.adminFields.name")}</p>
                <p>{I18n.t("Forms.adminFields.clientId")}</p>
                <p>{I18n.t("Forms.adminFields.tin")}</p>
                <p>{I18n.t("Forms.adminFields.cin")}</p>
                <p>&nbsp;</p>
              </div>
              <div className={styles.cardData}>
                <p>{psychLab?.name || emptyState}</p>
                <p>{psychLab?.clientId || emptyState}</p>
                <p>{psychLab?.tin || emptyState}</p>
                <p>{psychLab?.cin || emptyState}</p>
                <p>&nbsp;</p>
              </div>
            </div>
            <div className={styles.cardDataColumn}>
              <div className={styles.cardDataRowName}>
                <p>{I18n.t("Forms.adminFields.dateExpire")}</p>
                <p>{I18n.t("Forms.adminFields.status")}</p>
                <p>{I18n.t("Forms.adminFields.theme")}</p>
                <p>{I18n.t("Forms.adminFields.usersCnt")}</p>
                <p>{I18n.t("Forms.adminFields.examineesCnt")}</p>
              </div>
              <div className={styles.cardData}>
                <p>{psychLab ? FormatDate(psychLab.dateExpire, I18n.t("Format.ExamineeViewDateFormat")) : "-"}</p>
                <p>{psychLab ? getClientStatus(psychLab.isActive) : emptyState}</p>
                <p>{psychLab?.theme || emptyState}</p>
                <p>{psychLab?.userCount || emptyState}</p>
                <p>{psychLab?.examineeCount || emptyState}</p>
              </div>
            </div>
            <div className={styles.cardDataColumn}>
              <div className={styles.cardDataRowName}>
                <p>{I18n.t("Forms.adminFields.address")}</p>
                <p>{I18n.t("Forms.adminFields.postalCode")}</p>
                <p>{I18n.t("Forms.adminFields.city")}</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
              </div>
              <div className={styles.cardData}>
                <p>{psychLab?.address || emptyState}</p>
                <p>{psychLab?.postalCode || emptyState}</p>
                <p>{psychLab?.city || emptyState}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const setInfoPopupText = () => {
    acceptButtonText.current = I18n.t("Buttons.ok");
    contentText.current = I18n.t("Common.logAdminAsUserPopup.text");
    headerText.current = I18n.t("Common.logAdminAsUserPopup.header");
    setWarningPopup(true);
  }

  useEffect(() => {
    if (!updateAdminLabState.loading && updateAdminLabState.success) {
      handleNavigateToPage(dashboardPath);
    }
    if (!updateAdminLabState.loading && !updateAdminLabState.success && updateAdminLabState.error) {
      setInfoPopupText();
    }
  }, [updateAdminLabState.loading]);

  useEffect(() => {
    if (refreshNeededState && refreshNeeded.current) {
      refreshNeeded.current = false;
      void handleNavigateToPreviousPage();
    }
  }, [refreshNeededState]);


  return (
    <>
      {renderPage()}
    </>
  );
};

export default PsychLabDataSection;

import { RootState } from "../../store";

export const selectSelectedPsychLabId = (state: RootState) =>
  state.selectedPsychLab.selectedPsychLabId;

export const selectSelectedPsychLabData = (state: RootState) =>
  state.selectedPsychLab.selectedPsychLab;

export const selectSelectedPsychLabDataLoading = (state: RootState) =>
  state.selectedPsychLab.loadingPsychLabData;

export const selectSelectedPsychLabLoading = (state: RootState) =>
  state.selectedPsychLab.loading;

export const selectSelectedPsychLabUsers = (state: RootState) =>
  state.selectedPsychLab.users;

export const selectSelectedPsychLabUsersLoading = (state: RootState) =>
  state.selectedPsychLab.loadingPsychUsers;

export const selectSelectedPsychLabActivities = (state: RootState) =>
  state.selectedPsychLab.activities;

export const selectSelectedPsychLabActivitiesLoading = (state: RootState) =>
  state.selectedPsychLab.loadingPsychActivities;

export const selectSelectedPsychLabStatsLoading = (state: RootState) =>
  state.selectedPsychLab.loadingPsychStats;

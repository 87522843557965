import * as styles from "../forms.module.scss";
import React, { FC, useRef } from "react";
import { I18n } from "react-redux-i18n";
import FormDatePicker from "../Inputs/FormDatePicker";
import { useForm } from "react-hook-form";
import { FilterPsychLabActivitiesData } from "../types";
import FormInputDropdown from "../Inputs/FormInputDropdown";
import { activitiesFormOptions, activitiesStatusFormOptions, convertToNativeDate, translateFormOption } from "../utils";
import { useParams } from "@reach/router";
import { useDispatch } from "react-redux";
import { fetchActivityList } from "../../../redux/actions/adminPanel/selectedPsychLabActions";
import TertiaryButton from "../../Common/Buttons/TertiaryButton";
import SecondaryButton from "../../Common/Buttons/SecondaryButton";
import { Activities2Sort } from "../../../api/psychologicalLabApi";
import * as XLSX from "xlsx";


interface Props {
    sortedActivitiesData: Activities2Sort[];
}

const FormActivitiesFilters: FC<Props> = ({ sortedActivitiesData }) => {
    const { psychLabId } = useParams();
    const dispatch = useDispatch();

    const getDefaultDateFrom = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        return date;
    };

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        shouldUnregister: false,
        defaultValues: {
            dateFrom: getDefaultDateFrom(),
            dateTo: undefined,
            action: "ALL_ITEMS",
        },
    });

    const onSubmit = (data: FilterPsychLabActivitiesData) => {
        const formattedData = {
            ...data,
            dateFrom: convertToNativeDate(data.dateFrom),
            dateTo: convertToNativeDate(data.dateTo),
        };

        dispatch(fetchActivityList({ labId: psychLabId, ...formattedData }));
    };

    const exportToFile = () => {
        const textValues = [
            I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.activityId"),
            I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.activityDate"),
            I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.activityAction"),
            I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.activityStatus"),
            I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.activityContext"),
            I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.activityExecutor"),
        ]

        const dataToExport = sortedActivitiesData.map(item => ({
            [textValues[0]]: item.id,
            [textValues[1]]: item.date,
            [textValues[2]]: item.action,
            [textValues[3]]: item.status,
            [textValues[4]]: JSON.stringify(item.context),
            [textValues[5]]: item.executor
        }));

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.activityLogs"));

        const [currentDate] = new Date().toISOString().split('T');
        const fileName = `${I18n.t("AdminDashboard.PsychLabManagment.PsychLabView.tabs.activityLogsFile")}_${currentDate}.xls`;

        XLSX.writeFile(wb, fileName);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.filtersContainer}>
            <div>
                <div className={styles.fieldsSectionContainer}>
                    <div className={styles.fieldsContainer}>
                        <FormDatePicker
                            inputFormat={I18n.t("Format.ExamineeViewDateFormat")}
                            control={control}
                            name="dateFrom"
                            label={I18n.t("Forms.adminFields.dateFrom")}
                            setValue={setValue}
                            disableFuture
                        />
                        <FormDatePicker
                            inputFormat={I18n.t("Format.ExamineeViewDateFormat")}
                            control={control}
                            name="dateTo"
                            label={I18n.t("Forms.adminFields.dateTo")}
                            setValue={setValue}
                            disableFuture
                        />
                        <FormInputDropdown
                            longInput="longer"
                            control={control}
                            name="action"
                            label={I18n.t("Forms.adminFields.activityName")}
                            options={translateFormOption(activitiesFormOptions)}
                            setValue={setValue}
                        />

                    </div>
                    <div className={styles.fieldsContainer}>
                        <FormInputDropdown
                            control={control}
                            name="status"
                            label={I18n.t("Forms.adminFields.status")}
                            options={translateFormOption(activitiesStatusFormOptions)}
                        />
                    </div>
                </div>
                <div className={styles.buttonsContainerMaxWide}>
                    <TertiaryButton
                        text={I18n.t("Buttons.exportActivities")}
                        event={exportToFile}
                        disabled={sortedActivitiesData === undefined || sortedActivitiesData.length === 0}
                    />
                    <SecondaryButton
                        isSubmit
                        text={I18n.t("Buttons.showActivities")}
                    />
                </div>
            </div>
        </form>
    );
};

export default FormActivitiesFilters;


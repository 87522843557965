import { I18n } from "react-redux-i18n";
import * as styles from "./activitiesList.module.scss";
import React, { FC, useEffect, useMemo, useState } from "react";
import FormActivitiesFilters from "../../Forms/FormActivitiesFilters";
import { FormatDate } from "../../../utils";
import { Activities2Sort, PsychLabActivity } from "../../../api/psychologicalLabApi";
import ActivitiesEnhancedTable from "../SortedTable/activityTable";
import { useSelector } from "react-redux";
import { selectSelectedPsychLabActivities } from "../../../redux/selectors/adminPanel/selectedPsychLabSelectors";

const ActivitiesList: FC = () => {
    const activities: PsychLabActivity[] = useSelector(selectSelectedPsychLabActivities);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        // Inkrementujemy klucz odświeżania za każdym razem, gdy activities się zmieniają
        setRefreshKey(prevKey => prevKey + 1);
    }, [activities]);

    const sortedActivitiesData = useMemo(() => {
        const newSortData: Activities2Sort[] = [];
        if (activities && activities.length > 0) {
            activities.forEach(element => {
                newSortData.push({
                    id: element.id,
                    date: FormatDate(element.date.toString(), I18n.t("Format.ExamineeViewDateFormat")),
                    action: I18n.t(`Forms.options.activities.${element.action}`),
                    status: element.status === "SUCCESS" ? I18n.t("AdminDashboard.ActivitiesList.labStatus.success") :
                        I18n.t("AdminDashboard.ActivitiesList.labStatus.error"),
                    context: element.context,
                    executor: element.firstname ? `${element.firstname} ${element.lastname}` : "",
                });
            });
        }
        return newSortData;
    }, [activities, refreshKey]);

    const renderContent = () => {
        return (
            <>
                <div className={styles.filtersContainer}>
                    {<FormActivitiesFilters sortedActivitiesData={sortedActivitiesData} />}
                </div>
                {ActivitiesEnhancedTable(sortedActivitiesData, refreshKey)}
            </>
        );
    }

    return (renderContent());
};

export default ActivitiesList;

// extracted by mini-css-extract-plugin
export var h1Typography = "examination-module--h1-typography--QMRnM";
export var h2Typography = "examination-module--h2-typography--555Lj";
export var h2TypographySecond = "examination-module--h2-typography-second--K746X";
export var h3Typography = "examination-module--h3-typography--2dUyM";
export var h3TypographyError = "examination-module--h3-typography-error--t87+C";
export var h4Typography = "examination-module--h4-typography--kKK+u";
export var pTypography = "examination-module--p-typography--Kt1t3";
export var smallPTypography = "examination-module--small-p-typography--bQL-U";
export var sidebarTypography = "examination-module--sidebar-typography--1XvCq";
export var errorTypography = "examination-module--error-typography--lzz0r";
export var captionTypography = "examination-module--caption-typography--3-Rrd";
export var authMessageLabelTypography = "examination-module--auth-message-label-typography--PRFQR";
export var authMessageTypography = "examination-module--auth-message-typography--sjcNH";
export var versionInfoTypography = "examination-module--version-info-typography--2BSw+";
export var itemHidden = "examination-module--item-hidden--UQbiD";
export var ___gatsby = "examination-module--___gatsby--L6u+o";
export var gatsbyFocusWrapper = "examination-module--gatsby-focus-wrapper--zLzKE";
export var breadcrumbsContainer = "examination-module--breadcrumbs-container--4VgrO";
export var headerContainer = "examination-module--header-container--VONwi";
export var examinationInfoContainer = "examination-module--examination-info-container--Jn8+E";
export var archiveExaminationInfoContainer = "examination-module--archive-examination-info-container--Moxp6";
export var examinationInfoContainerMobile = "examination-module--examination-info-container-mobile--E1-Pg";
export var cardContentContainer = "examination-module--card-content-container--wWsl4";
export var cardListContainer = "examination-module--card-list-container--1VDuM";
export var cardHeaderSection = "examination-module--card-header-section--XnDUp";
export var cardHeaderSectionArchive = "examination-module--card-header-section-archive--D8Dps";
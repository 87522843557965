// extracted by mini-css-extract-plugin
export var h1Typography = "judgment-module--h1-typography--vK5Du";
export var h2Typography = "judgment-module--h2-typography--5Cyde";
export var h2TypographySecond = "judgment-module--h2-typography-second---kS7U";
export var h3Typography = "judgment-module--h3-typography--U8cfe";
export var h3TypographyError = "judgment-module--h3-typography-error--TY-M4";
export var h4Typography = "judgment-module--h4-typography--6jNHm";
export var pTypography = "judgment-module--p-typography--sqfKp";
export var smallPTypography = "judgment-module--small-p-typography--Dx2IV";
export var sidebarTypography = "judgment-module--sidebar-typography--tJ+rE";
export var errorTypography = "judgment-module--error-typography--Z1Df7";
export var captionTypography = "judgment-module--caption-typography--vWZoy";
export var authMessageLabelTypography = "judgment-module--auth-message-label-typography--4KAZE";
export var authMessageTypography = "judgment-module--auth-message-typography--GNue3";
export var versionInfoTypography = "judgment-module--version-info-typography--rSluu";
export var itemHidden = "judgment-module--item-hidden--grP0X";
export var ___gatsby = "judgment-module--___gatsby--vp1Zg";
export var gatsbyFocusWrapper = "judgment-module--gatsby-focus-wrapper--h6hGv";
export var breadcrumbsContainer = "judgment-module--breadcrumbs-container--46nCa";
export var headerContainer = "judgment-module--header-container--iXkr8";
export var judgmentContainer = "judgment-module--judgment-container--jlkaB";
export var judgmentContainerMobile = "judgment-module--judgment-container-mobile--bQJcb";
export var fieldNextLegalBasis = "judgment-module--field-next-legal-basis--gDXCh";
export var fieldsContainer = "judgment-module--fields-container--OhTtF";
export var fieldsSectionContainer = "judgment-module--fields-section-container--Yk2rg";
export var fieldJudgmentContainer = "judgment-module--field-judgment-container--lDDXN";
export var textareaContainer = "judgment-module--textarea-container--zAGB7";
export var buttonsContainer = "judgment-module--buttons-container--Qi-FT";
export var formContainer = "judgment-module--form-container--2bIt+";
// extracted by mini-css-extract-plugin
export var h1Typography = "search-module--h1-typography--ekF7N";
export var h2Typography = "search-module--h2-typography--sRwuw";
export var h2TypographySecond = "search-module--h2-typography-second--H8-OT";
export var h3Typography = "search-module--h3-typography--SEcy5";
export var h3TypographyError = "search-module--h3-typography-error--SAZtR";
export var h4Typography = "search-module--h4-typography--v2dXN";
export var pTypography = "search-module--p-typography--0DjrH";
export var smallPTypography = "search-module--small-p-typography--Xib5D";
export var sidebarTypography = "search-module--sidebar-typography--BsSQg";
export var errorTypography = "search-module--error-typography--z30E7";
export var captionTypography = "search-module--caption-typography--x5+Rc";
export var authMessageLabelTypography = "search-module--auth-message-label-typography--XRtEG";
export var authMessageTypography = "search-module--auth-message-typography--94djZ";
export var versionInfoTypography = "search-module--version-info-typography--WvZIR";
export var itemHidden = "search-module--item-hidden--TC4PD";
export var ___gatsby = "search-module--___gatsby--Xvitv";
export var gatsbyFocusWrapper = "search-module--gatsby-focus-wrapper--Wd0Lj";
export var headerContainer = "search-module--header-container--l3gH1";
export var headerText = "search-module--header-text--A7Gyd";
export var mobileHeaderText = "search-module--mobile-header-text--fyT1Y";
export var buttonContainer = "search-module--button-container--Pu44s";
export var searchBarContainer = "search-module--search-bar-container--zU79o";
export var searchBarForm = "search-module--search-bar-form--RNzLR";
export var searchBarContainerDesktop = "search-module--search-bar-container-desktop--5DjRD";
export var searchBarButtonContainer = "search-module--search-bar-button-container--srpGl";
export var messageContainer = "search-module--message-container--pymI9";
export var messageIcon = "search-module--message-icon--s+poS";
export var messageLabel = "search-module--message-label--25tWZ";
export var messageText = "search-module--message-text--QFUfR";
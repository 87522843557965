// extracted by mini-css-extract-plugin
export var h1Typography = "dashboard-module--h1-typography--QljR0";
export var h2Typography = "dashboard-module--h2-typography--OhjI9";
export var h2TypographySecond = "dashboard-module--h2-typography-second--kiY--";
export var h3Typography = "dashboard-module--h3-typography--fJUPa";
export var h3TypographyError = "dashboard-module--h3-typography-error--5u6Xq";
export var h4Typography = "dashboard-module--h4-typography--dtscJ";
export var pTypography = "dashboard-module--p-typography--I05AQ";
export var smallPTypography = "dashboard-module--small-p-typography--NX3eA";
export var sidebarTypography = "dashboard-module--sidebar-typography--l0OwE";
export var errorTypography = "dashboard-module--error-typography--BvXAF";
export var captionTypography = "dashboard-module--caption-typography--JE96+";
export var footerText = "dashboard-module--footer-text--R9s-U";
export var authMessageLabelTypography = "dashboard-module--auth-message-label-typography--5ipz+";
export var authMessageTypography = "dashboard-module--auth-message-typography--Q0zj3";
export var versionInfoTypography = "dashboard-module--version-info-typography--ow-V7";
export var itemHidden = "dashboard-module--item-hidden--O+4Up";
export var ___gatsby = "dashboard-module--___gatsby--vjDGz";
export var gatsbyFocusWrapper = "dashboard-module--gatsby-focus-wrapper--Z4zOe";
export var headerContainer = "dashboard-module--header-container--Ha9lX";
export var headerText = "dashboard-module--header-text--9fxB7";
export var mobileHeaderText = "dashboard-module--mobile-header-text--kAODZ";
export var noPadding = "dashboard-module--no-padding--jJZHF";
export var footerContainer = "dashboard-module--footer-container--psLI0";
export var messageContainer = "dashboard-module--message-container--IXwbj";
export var messageText = "dashboard-module--message-text--GogT3";
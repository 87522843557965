// extracted by mini-css-extract-plugin
export var h1Typography = "examinationCard-module--h1-typography--2Li2R";
export var h2Typography = "examinationCard-module--h2-typography--LmyJg";
export var h2TypographySecond = "examinationCard-module--h2-typography-second--wP-gu";
export var h3Typography = "examinationCard-module--h3-typography--Aty3K";
export var h3TypographyError = "examinationCard-module--h3-typography-error--UyyaM";
export var h4Typography = "examinationCard-module--h4-typography--7i00l";
export var pTypography = "examinationCard-module--p-typography--qPdiF";
export var smallPTypography = "examinationCard-module--small-p-typography--nvZ3b";
export var sidebarTypography = "examinationCard-module--sidebar-typography--0h7JN";
export var errorTypography = "examinationCard-module--error-typography--0MPhf";
export var captionTypography = "examinationCard-module--caption-typography--ZEmJw";
export var authMessageLabelTypography = "examinationCard-module--auth-message-label-typography--zIKqF";
export var authMessageTypography = "examinationCard-module--auth-message-typography--Orb9M";
export var versionInfoTypography = "examinationCard-module--version-info-typography--6D0fz";
export var itemHidden = "examinationCard-module--item-hidden--9VsX0";
export var ___gatsby = "examinationCard-module--___gatsby--HbI7c";
export var gatsbyFocusWrapper = "examinationCard-module--gatsby-focus-wrapper--JfEGu";
export var breadcrumbsContainer = "examinationCard-module--breadcrumbs-container--qTgtW";
export var headerContainer = "examinationCard-module--header-container--8Mhtv";
export var headerButtonsContainer = "examinationCard-module--header-buttons-container--ZnPal";
export var desktopHeader = "examinationCard-module--desktop-header--SfhFn";
export var form = "examinationCard-module--form--804fb";
export var buttons = "examinationCard-module--buttons--phEdx";

import React, { FC, useEffect, useState } from "react";
import * as styles from "../popup.module.scss";
import { I18n } from "react-redux-i18n";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { muiStylesPopup } from "../muistylesPopup";
import type { ComponentType } from 'react'
import type { ReactJsonViewProps } from 'react-json-view'

interface PopupProps {
    isOpen: boolean;
    onClose: (
        event?: Record<string, unknown>, // any object
        reason?: "backdropClick" | "escapeKeyDown"
    ) => void;
    title?: string;
    contentText?: any | null;
    isError?: boolean;
}

const JsonInfoPopup: FC<PopupProps> = ({
    isOpen,
    onClose,
    title,
    contentText,
}) => {
    const [ReactJson, setReactJson] = useState<ComponentType<ReactJsonViewProps> | null>(null)

    useEffect(() => {
        void import('react-json-view')
            .then((module) => {
                setReactJson(() => module.default);
                return module.default; // zwracamy wartość z then
            })
            .catch((error) => {
                console.error('Failed to load component:', error);
                return null; // zwracamy wartość w przypadku błędu
            });
    }, []);

    return (
        isOpen ?
            <Dialog
                maxWidth="lg"
                open={isOpen}
                onClose={(event, reason) => {
                    if (reason === "escapeKeyDown") {
                        onClose();
                    }
                }}
                PaperProps={{ sx: muiStylesPopup.dialogPaper }}
            >
                <DialogTitle sx={muiStylesPopup.dialogHeader}>
                    <h2>{title}</h2>
                </DialogTitle>
                <DialogContent sx={muiStylesPopup.dialogContent}>
                    <div className={styles.jsonContent}>
                        {ReactJson && <ReactJson src={contentText} enableClipboard={false} />}
                    </div>
                    <div className={styles.buttonsContainer}>
                        <PrimaryButton event={onClose} text={I18n.t("Buttons.close")} />
                    </div>
                </DialogContent>
            </Dialog>
            : null
    );
};

export default JsonInfoPopup;

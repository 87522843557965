
import * as styles from "../BaseTable/baseTable.module.scss";
import React, { FC } from "react";
import { BaseTableMobile } from "../BaseTable/Mobile";
import { I18n } from "react-redux-i18n";
import { TableRow, TableCell } from "@mui/material";
import BaseTableDesktop from "../BaseTable/Desktop";
import { handleNavigateToExamineeProfile } from "../../../utils/paths";
import { useIsMobile } from "../../../utils/hooks";
import { PsychologicalLab } from "../../../api/psychologicalLabApi";
import PsychologicalLabsList from "../PsychologicalLabsList";

const head = [
  I18n.t("ExamineeList.labels.firstName"),
  I18n.t("ExamineeList.labels.lastName"),
  I18n.t("ExamineeList.labels.peselSlashIdentifier"),
  I18n.t("ExamineeList.labels.folder"),
];

interface Props {
  psychLabs: PsychologicalLab[];
}

const AdminSearchResultsList: FC<Props> = ({ psychLabs }) => {
  const isMobile = useIsMobile();

  const mobileContent = () => {
    // psychLabs.map((psychLab) => {
    //   return (
    //     <BaseTableMobile
    //       key={psychLab.id}
    //       headerText={`${psychLab.firstName} ${psychLab.lastName}`}
    //       iconType="naviagtion"
    //       handleNavigation={() => handleNavigateToExamineeProfile(psychLab.id)}
    //     >
    //       <>
    //         <div className={styles.mobileInfoContainer}>
    //           <div className={styles.mobileInfoHeader}>
    //             {I18n.t("ExamineeList.labels.peselSlashIdentifier")}
    //           </div>
    //           <div className={styles.mobileInfo}>
    //             {psychLab.identificationNumber}
    //           </div>
    //         </div>
    //         <div className={styles.mobileInfoContainer}>
    //           <div className={styles.mobileInfoHeader}>
    //             {I18n.t("ExamineeList.labels.folder")}
    //           </div>
    //           <div className={styles.mobileInfo}>
    //             {psychLab.directory?.name}
    //           </div>
    //         </div>
    //       </>
    //     </BaseTableMobile>
    //   );
    // });
  }

  const desktopContent = () => (
    <PsychologicalLabsList psychLabs={psychLabs} />
  );

  return <>{isMobile ? mobileContent() : desktopContent()}</>;
};

export default AdminSearchResultsList;

import * as styles from "../../judgment.module.scss";
import React, { FC, useEffect } from "react";
import dayjs from "dayjs";
import { useParams } from "@reach/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useIsFirstRender } from "usehooks-ts";
import { updateJudgment } from "../../../../../redux/actions/documentation/judgmentActions";
import { selectJudgment } from "../../../../../redux/selectors/documentation/judgmentSelectors";
import { selectSelectedExamineeData } from "../../../../../redux/selectors/examinees/selectedExamineeSelectors";
import store from "../../../../../redux/store";
import { IsObjectEmpty } from "../../../../../utils";
import { handleNavigateToPreviousPage } from "../../../../../utils/paths";
import { dispatchNotification } from "../../../../../utils/redux";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../../Common/Buttons/SecondaryButton";
import FormDatePicker from "../../../../Forms/Inputs/FormDatePicker";
import FormInputMultiRadio from "../../../../Forms/Inputs/FormInputMultiRadio";
import { MultiInputOptions } from "../../../../Forms/types";
import {
  schemaProfessionalDriverAnonymous,
  schemaProfessionalDriverPESEL,
  schemaProfessionalDriverWithoutPESEL,
} from "../../schema";
import { commonJudgmentDeafults } from "../../utils";
import { DrivingInstructorExaminerData } from "../../types";
import FormInputText from "../../../../Forms/Inputs/FormInputText";
import FormDocumentExaminee from "../../../../Forms/FormDocumentExaminee";
import { isFieldRequired, examineeRequiredFields } from "../../../ExaminationCard/utils";
import FromInputCheckbox from "../../../../Forms/Inputs/FormInputCheckbox";
import { DrivingInstructorNextExaminationLegalBasis } from "../../../types";

const DrivingInstructorExaminerJudgment: FC = () => {
  const { examinationId, documentId, judgementName } = useParams();

  const examinee = useSelector(selectSelectedExamineeData);
  const judgment = useSelector(selectJudgment) as DrivingInstructorExaminerData;

  const isFirst = useIsFirstRender();

  const setExamineeUnder60 = () => {
    const birthDate = (examinee?.birthDate || "");
    let examineeUnder60yo = false;
    if (birthDate !== "") {
      const currentAgeExaminee = dayjs(judgment?.completionDate || "").diff(dayjs(examinee?.birthDate || ""), "year");
      examineeUnder60yo = currentAgeExaminee < 60;
    }

    return examineeUnder60yo;
  }

  const handleNextExaminationDate = () =>
  (setExamineeUnder60()
    ? dayjs(judgment?.completionDate).add(5, "year").subtract(1, "day").toISOString()
    : dayjs(judgment?.completionDate).add(30, "month").subtract(1, "day").toISOString());

  const handleNextExaminationLegalBasis = (nextExaminationLegalBasis?: DrivingInstructorNextExaminationLegalBasis) => {
    let text = nextExaminationLegalBasis;
    if (nextExaminationLegalBasis === null) {
      text = (setExamineeUnder60() ? "ART_1" : "ART_2");
    }

    return text;
  }

  const getSchema = (data: DrivingInstructorExaminerData) => {
    let schema = null;

    if (data?.pesel) {
      schema = schemaProfessionalDriverPESEL;
    }
    if ((data?.pesel === null || data?.pesel === "") && data?.anonymous !== null && data.anonymous) {
      schema = schemaProfessionalDriverAnonymous;
    }

    return schema ?? schemaProfessionalDriverWithoutPESEL;
  }

  const methods = useForm<DrivingInstructorExaminerData>({
    reValidateMode: "onChange",
    resolver: yupResolver(getSchema(judgment)),
    defaultValues: {
      ...commonJudgmentDeafults(judgment),
      judgmentId: judgment?.judgmentId || "",
      isSuitable: judgment?.isSuitable,
      nextExamination: judgment?.nextExamination || handleNextExaminationDate(),
      nextExaminationLegalBasis: handleNextExaminationLegalBasis(judgment?.nextExaminationLegalBasis),
      judgmentNo: judgment?.judgmentNo?.toString() || "",
      isAppeal: judgment?.isAppeal,
    },
  });

  const onSubmit = async (data: DrivingInstructorExaminerData) => {
    await store
      .dispatch(
        updateJudgment({
          ...data,
          examinationId,
          documentId,
        })
      )
      .then(() => void handleNavigateToPreviousPage());
  };

  useEffect(() => {
    if (!isFirst && !IsObjectEmpty(methods.formState.errors)) {
      dispatchNotification("error", I18n.t("Forms.snackbar.required"));
    }
  }, [methods.formState.errors, isFirst]);

  const judgmentOptions: MultiInputOptions[] =
    judgementName === "examiner"
      ? [
        {
          label: I18n.t(
            "DocumentationManagement.judgment.drivingExaminer.true"
          ),
          value: true,
        },
        {
          label: I18n.t(
            "DocumentationManagement.judgment.drivingExaminer.false"
          ),
          value: false,
        },
      ]
      : [
        {
          label: I18n.t(
            "DocumentationManagement.judgment.drivingInstructor.true"
          ),
          value: true,
        },
        {
          label: I18n.t(
            "DocumentationManagement.judgment.drivingInstructor.false"
          ),
          value: false,
        },
      ];

  const getNextExaminationlegalBasis = () => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return I18n.t(`DocumentationManagement.judgment.examiner_${methods.getValues("nextExaminationLegalBasis")}`);
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={styles.fieldsContainer}>
            <FromInputCheckbox
              name="isAppeal"
              control={methods.control}
              label={I18n.t("DocumentationManagement.judgment.isAppealTitle")} />
          </div>
          <div>
            <h2>{I18n.t("DocumentationManagement.judgment.judgmentNumber")}</h2>
            <div className={styles.fieldsContainer}>
              <FormInputText
                control={methods.control}
                name="judgmentNo"
                required={isFieldRequired(getSchema(judgment), "judgmentNo")}
                label={I18n.t("Forms.fields.judgmentNumber")}
                error={!!methods.formState.errors.judgmentNo}
              />
            </div>
          </div>
          <div className={styles.formContainer}>
            <FormDocumentExaminee requiredFields={examineeRequiredFields(getSchema(judgment))} initialData={judgment} />
          </div>
          <h2>{I18n.t("DocumentationManagement.judgment.statement")}</h2>
          <FormInputMultiRadio
            name="isSuitable"
            control={methods.control}
            options={judgmentOptions}
            error={!!methods.formState.errors.isSuitable?.message}
          />
          <div className={styles.fieldsContainer}>
            <h2>{I18n.t("DocumentationManagement.judgment.nextLegalBasis")}</h2>
            <p className={styles.fieldNextLegalBasis}>{getNextExaminationlegalBasis()}</p>
          </div>
          <div className={styles.fieldsSectionContainer}>
            <div className={styles.fieldsContainer}>
              <FormDatePicker
                control={methods.control}
                name="nextExamination"
                label={I18n.t("Forms.fields.nextExamination")}
                error={!!methods.formState.errors.nextExamination}
              />
              <FormDatePicker
                control={methods.control}
                name="issueDate"
                label={I18n.t("Forms.fields.documentDate")}
                error={!!methods.formState.errors.issueDate}
              />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <SecondaryButton
              text={I18n.t("Buttons.cancel")}
              event={handleNavigateToPreviousPage}
            />
            <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default DrivingInstructorExaminerJudgment;

// extracted by mini-css-extract-plugin
export var h1Typography = "adminsearch-module--h1-typography--WCjLM";
export var h2Typography = "adminsearch-module--h2-typography--p99hn";
export var h2TypographySecond = "adminsearch-module--h2-typography-second--FrVWl";
export var h3Typography = "adminsearch-module--h3-typography--bmHz0";
export var h3TypographyError = "adminsearch-module--h3-typography-error--O3j-4";
export var h4Typography = "adminsearch-module--h4-typography--yld7y";
export var pTypography = "adminsearch-module--p-typography--OzyIP";
export var smallPTypography = "adminsearch-module--small-p-typography--qQdVU";
export var sidebarTypography = "adminsearch-module--sidebar-typography--Nnobx";
export var errorTypography = "adminsearch-module--error-typography--TUMbJ";
export var captionTypography = "adminsearch-module--caption-typography--o22-R";
export var authMessageLabelTypography = "adminsearch-module--auth-message-label-typography--9qbtk";
export var authMessageTypography = "adminsearch-module--auth-message-typography--MKwyC";
export var versionInfoTypography = "adminsearch-module--version-info-typography--R+84w";
export var itemHidden = "adminsearch-module--item-hidden--MOmqu";
export var ___gatsby = "adminsearch-module--___gatsby--7GcWG";
export var gatsbyFocusWrapper = "adminsearch-module--gatsby-focus-wrapper--I+0ij";
export var headerContainer = "adminsearch-module--header-container--aTwts";
export var headerText = "adminsearch-module--header-text--NNALl";
export var mobileHeaderText = "adminsearch-module--mobile-header-text--MuPUk";
export var buttonContainer = "adminsearch-module--button-container--rioL+";
export var searchBarContainer = "adminsearch-module--search-bar-container--iSWIy";
export var searchBarForm = "adminsearch-module--search-bar-form--bgwRQ";
export var searchBarContainerDesktop = "adminsearch-module--search-bar-container-desktop--mD+SX";
export var searchBarButtonContainer = "adminsearch-module--search-bar-button-container--VEggf";
export var messageContainer = "adminsearch-module--message-container--v6nxv";
export var messageIcon = "adminsearch-module--message-icon--ED4qv";
export var messageLabel = "adminsearch-module--message-label--0VCNg";
export var messageText = "adminsearch-module--message-text--DG2XI";
// extracted by mini-css-extract-plugin
export var h1Typography = "addExaminee-module--h1-typography--YFtos";
export var h2Typography = "addExaminee-module--h2-typography--UP7Az";
export var h2TypographySecond = "addExaminee-module--h2-typography-second--4-wOw";
export var h3Typography = "addExaminee-module--h3-typography--GQS-7";
export var h3TypographyError = "addExaminee-module--h3-typography-error--btW6m";
export var h4Typography = "addExaminee-module--h4-typography--A6jit";
export var pTypography = "addExaminee-module--p-typography--Q7rQ+";
export var smallPTypography = "addExaminee-module--small-p-typography--LkRwT";
export var sidebarTypography = "addExaminee-module--sidebar-typography--bBmU0";
export var errorTypography = "addExaminee-module--error-typography--SKMc1";
export var captionTypography = "addExaminee-module--caption-typography--1YoNd";
export var authMessageLabelTypography = "addExaminee-module--auth-message-label-typography--M53qz";
export var authMessageTypography = "addExaminee-module--auth-message-typography--prwqB";
export var versionInfoTypography = "addExaminee-module--version-info-typography--ch6dN";
export var itemHidden = "addExaminee-module--item-hidden--J0mjl";
export var ___gatsby = "addExaminee-module--___gatsby--RPth-";
export var gatsbyFocusWrapper = "addExaminee-module--gatsby-focus-wrapper--s5Vdu";
export var container = "addExaminee-module--container--1W8Dl";
export var headerContainer = "addExaminee-module--header-container---dv-1";
export var contentContainer = "addExaminee-module--content-container--DWtHf";
export var singleCheckbox = "addExaminee-module--single-checkbox--9IkPY";
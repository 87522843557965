// extracted by mini-css-extract-plugin
export var h1Typography = "editExaminee-module--h1-typography--0GNVZ";
export var h2Typography = "editExaminee-module--h2-typography--YAjMj";
export var h2TypographySecond = "editExaminee-module--h2-typography-second--5St34";
export var h3Typography = "editExaminee-module--h3-typography--c5Lt8";
export var h3TypographyError = "editExaminee-module--h3-typography-error--rGEBq";
export var h4Typography = "editExaminee-module--h4-typography--I2v6y";
export var pTypography = "editExaminee-module--p-typography--iAbzV";
export var smallPTypography = "editExaminee-module--small-p-typography--ga+-v";
export var sidebarTypography = "editExaminee-module--sidebar-typography--4lad5";
export var errorTypography = "editExaminee-module--error-typography--+3gCl";
export var captionTypography = "editExaminee-module--caption-typography--YIJBY";
export var authMessageLabelTypography = "editExaminee-module--auth-message-label-typography--z-d1b";
export var authMessageTypography = "editExaminee-module--auth-message-typography--scTM7";
export var versionInfoTypography = "editExaminee-module--version-info-typography--HATeh";
export var itemHidden = "editExaminee-module--item-hidden--yS2g8";
export var ___gatsby = "editExaminee-module--___gatsby--ba756";
export var gatsbyFocusWrapper = "editExaminee-module--gatsby-focus-wrapper--VKz9R";
export var container = "editExaminee-module--container--pP+z1";
export var headerContainer = "editExaminee-module--header-container--aLZ09";
export var contentContainer = "editExaminee-module--content-container--Kf4Rm";
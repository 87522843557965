import * as styles from "../adminsearch.module.scss";
import React, { FC } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import { I18n } from "react-redux-i18n";
import { iconBasicColor } from "../../../../styles/mui/theme";
import { useIsMobile } from "../../../../utils/hooks";

interface Props {
  value: string;
  onValueChange: (newFilter: string | undefined) => void;
}

export const AdminSearchBar: FC<Props> = ({ onValueChange, value }) => {
  const isMobile = useIsMobile();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onValueChange) {
      onValueChange(event.target.value);
    }
  };
  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    onValueChange(value);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.searchBarForm}>
        <TextField
          sx={{ width: "100%" }}
          id="search-engine-input"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={iconBasicColor} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          placeholder={
            isMobile
              ? I18n.t("AdminSearchEngine.placeholderMobile")
              : I18n.t("AdminSearchEngine.placeholderDesktop")
          }
          onChange={handleChange}
          value={value}
        />
      </form>
    </>
  );
};

// extracted by mini-css-extract-plugin
export var h1Typography = "editPsychLab-module--h1-typography--XyN9V";
export var h2Typography = "editPsychLab-module--h2-typography--UWmn-";
export var h2TypographySecond = "editPsychLab-module--h2-typography-second--++2NJ";
export var h3Typography = "editPsychLab-module--h3-typography--dI19V";
export var h3TypographyError = "editPsychLab-module--h3-typography-error--MqxEj";
export var h4Typography = "editPsychLab-module--h4-typography--VDbzW";
export var pTypography = "editPsychLab-module--p-typography--NKmg2";
export var smallPTypography = "editPsychLab-module--small-p-typography--uHjYf";
export var sidebarTypography = "editPsychLab-module--sidebar-typography--58mGB";
export var errorTypography = "editPsychLab-module--error-typography--Cgyv-";
export var captionTypography = "editPsychLab-module--caption-typography--D1LC5";
export var authMessageLabelTypography = "editPsychLab-module--auth-message-label-typography--fpEOc";
export var authMessageTypography = "editPsychLab-module--auth-message-typography--l9gl-";
export var versionInfoTypography = "editPsychLab-module--version-info-typography--bnyIu";
export var itemHidden = "editPsychLab-module--item-hidden--RriC4";
export var ___gatsby = "editPsychLab-module--___gatsby--lwzBU";
export var gatsbyFocusWrapper = "editPsychLab-module--gatsby-focus-wrapper--eoaW-";
export var container = "editPsychLab-module--container--+-V4V";
export var headerContainer = "editPsychLab-module--header-container--3ktiI";
export var contentContainer = "editPsychLab-module--content-container--FHY+n";
export var singleCheckbox = "editPsychLab-module--single-checkbox--bbMwl";
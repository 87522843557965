// extracted by mini-css-extract-plugin
export var h1Typography = "licenseSection-module--h1-typography--b66Bl";
export var h2Typography = "licenseSection-module--h2-typography--xgShm";
export var h2TypographySecond = "licenseSection-module--h2-typography-second--gFVPa";
export var h3Typography = "licenseSection-module--h3-typography--25qoX";
export var h3TypographyError = "licenseSection-module--h3-typography-error--1EfR5";
export var h4Typography = "licenseSection-module--h4-typography--qGDRp";
export var pTypography = "licenseSection-module--p-typography--JMb8g";
export var smallPTypography = "licenseSection-module--small-p-typography--3j12a";
export var sidebarTypography = "licenseSection-module--sidebar-typography--fWTop";
export var errorTypography = "licenseSection-module--error-typography--Lt120";
export var captionTypography = "licenseSection-module--caption-typography--QXvcJ";
export var authMessageLabelTypography = "licenseSection-module--auth-message-label-typography--XXELQ";
export var authMessageTypography = "licenseSection-module--auth-message-typography--uQARs";
export var versionInfoTypography = "licenseSection-module--version-info-typography--iNxCL";
export var itemHidden = "licenseSection-module--item-hidden--GiTTJ";
export var ___gatsby = "licenseSection-module--___gatsby--R23Dr";
export var gatsbyFocusWrapper = "licenseSection-module--gatsby-focus-wrapper--vsShQ";
export var cardContentContainer = "licenseSection-module--card-content-container--6OQkF";
export var cardContentDataContainer = "licenseSection-module--card-content-data-container--BkYlV";
export var cardListContainer = "licenseSection-module--card-list-container--g+69Z";
export var messageContainer = "licenseSection-module--message-container--TKEsI";
export var cardDataColumn = "licenseSection-module--card-data-column--VMc8M";
export var cardData = "licenseSection-module--card-data--wGCH1";
export var cardDataRowName = "licenseSection-module--card-data-row-name--XUqya";
export var cardContentButtons = "licenseSection-module--card-content-buttons--Btt2w";
import * as styles from "./adminsearch.module.scss";
import React, { FC, useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import clsx from "clsx";
import TertiaryButton from "../../Common/Buttons/TertiaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useIsMobile } from "../../../utils/hooks";
import Loader from "../../Common/Loader";
import { AdminSearchBar } from "./AdminSearchbar";
import AdminSearchInformation from "./AdminSearchInformation";
import AdminSearchResultsList from "../../Lists/AdminSearchResultsList";
import { fetchSerchedPsychologicalLabs } from "../../../redux/actions/adminPanel/psychologicalLabsActions";
import { selectSearchedPsychLabsList, selectsSearchedPsychLabBarFilter, selectIsLoadingSearchedPsychLabsList } from "../../../redux/selectors/adminPanel/psychologicalLabsSelectors";
import { clearSearchBarPsychLabsFilter, clearSearchedPsychLabs, setSearchPsychLabsBarFilter } from "../../../redux/reducers/adminPanel/psychologicalLabsReducers";

const AdminSearchEngine: FC = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [localFilter, setLocalFilter] = useState<string>("");

  const psychLabList = useSelector(selectSearchedPsychLabsList);
  const isLoading = useSelector(selectIsLoadingSearchedPsychLabsList);
  const searchFilter = useSelector(selectsSearchedPsychLabBarFilter);

  const onValueChange = (newFilter: string | undefined) => {
    setLocalFilter(newFilter ? newFilter : "");
  };

  const confirmFilterChange = () => {
    dispatch(setSearchPsychLabsBarFilter(localFilter));
  };

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter" && localFilter) {
        event.preventDefault();
        confirmFilterChange();
      }
    };

    window.addEventListener("keydown", keyDownHandler);

    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [localFilter]);

  useEffect(() => {
    if (searchFilter !== undefined) {
      dispatch(fetchSerchedPsychologicalLabs(searchFilter));
    }
  }, [searchFilter]);

  useEffect(() => {
    return () => {
      dispatch(clearSearchedPsychLabs());
      dispatch(clearSearchBarPsychLabsFilter());
    };
  }, []);

  return (
    <>
      {isMobile && (
        <div className={styles.headerContainer}>
          <h1 className={!isMobile ? styles.headerText : styles.mobileHeaderText}>{I18n.t("AdminSearchEngine.header")}</h1>
        </div>
      )}
      <div
        className={clsx([
          styles.searchBarContainer,
          !isMobile && styles.searchBarContainerDesktop,
        ])}
      >
        <AdminSearchBar value={localFilter} onValueChange={onValueChange} />

        {!isMobile && (
          <span className={styles.searchBarButtonContainer}>
            <TertiaryButton
              text={I18n.t("Buttons.search")}
              event={confirmFilterChange}
              disabled={localFilter === ""}
            />
          </span>
        )}
      </div>
      {isLoading ? (
        <Loader loading />
      ) : psychLabList.length > 0 ? (
        <AdminSearchResultsList psychLabs={psychLabList} />
      ) : localFilter ? (
        <AdminSearchInformation noResults />
      ) : (
        <AdminSearchInformation />
      )}
    </>
  );
};

export default AdminSearchEngine;
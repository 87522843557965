import * as styles from "../../judgment.module.scss";
import React, { FC, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useForm, FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useIsFirstRender } from "usehooks-ts";
import { useParams } from "@reach/router";
import { IsObjectEmpty } from "../../../../../utils";
import { handleNavigateToPreviousPage } from "../../../../../utils/paths";
import { dispatchNotification } from "../../../../../utils/redux";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../../Common/Buttons/SecondaryButton";
import FormDocumentExaminee from "../../../../Forms/FormDocumentExaminee";
import FormDatePicker from "../../../../Forms/Inputs/FormDatePicker";
import FormInputMultiRadio from "../../../../Forms/Inputs/FormInputMultiRadio";
import { MultiInputOptions } from "../../../../Forms/types";
import {
  schemaPhysicalProtectionAnonymous,
  schemaPhysicalProtectionPESEL,
  schemaPhysicalProtectionWithoutPESEL,
} from "../../schema";

import FormInputText from "../../../../Forms/Inputs/FormInputText";
import store from "../../../../../redux/store";
import { PhysicalProtectionJudgmentData } from "../../types";
import { commonJudgmentDeafults } from "../../utils";
import { updateJudgment } from "../../../../../redux/actions/documentation/judgmentActions";
import { selectJudgment } from "../../../../../redux/selectors/documentation/judgmentSelectors";
import { isFieldRequired, examineeRequiredFields } from "../../../ExaminationCard/utils";
import FromInputCheckbox from "../../../../Forms/Inputs/FormInputCheckbox";
import { selectSelectedExamineeData } from "../../../../../redux/selectors/examinees/selectedExamineeSelectors";

const PhysicalProtectionJudgment: FC = () => {
  const isFirst = useIsFirstRender();
  const { examinationId, documentId } = useParams();

  const judgment = useSelector(
    selectJudgment
  ) as PhysicalProtectionJudgmentData;
  const examinee = useSelector(selectSelectedExamineeData);

  const setExamineeUnder60 = () => {
    const birthDate = (examinee?.birthDate || "");
    let examineeUnder60yo = false;
    if (birthDate !== "") {
      const currentAgeExaminee = dayjs(judgment?.completionDate || "").diff(dayjs(examinee?.birthDate || ""), "year");
      examineeUnder60yo = currentAgeExaminee < 60;
    }

    return examineeUnder60yo;
  }

  const handleNextExaminationDate = () =>
  (setExamineeUnder60()
    ? dayjs(judgment?.completionDate).add(5, "year").subtract(1, "day").toISOString()
    : dayjs(judgment?.completionDate).add(30, "month").subtract(1, "day").toISOString());

  const getSchema = (data: PhysicalProtectionJudgmentData) => {
    let schema = null;

    if (data?.pesel) {
      schema = schemaPhysicalProtectionPESEL;
    }
    if ((data?.pesel === null || data?.pesel === "") && data?.anonymous !== null && data.anonymous) {
      schema = schemaPhysicalProtectionAnonymous;
    }

    return schema ?? schemaPhysicalProtectionWithoutPESEL;
  }

  const methods = useForm<PhysicalProtectionJudgmentData>({
    reValidateMode: "onChange",
    resolver: yupResolver(getSchema(judgment)),
    defaultValues: {
      ...commonJudgmentDeafults(judgment),
      issueCity: judgment?.issueCity || "",
      isSuitable: judgment?.isSuitable,
      judgmentId: judgment?.judgmentId?.toString() || "",
      judgmentNo: judgment?.judgmentNo?.toString() || "",
      year: judgment?.year || "",
      nextExamination: judgment?.nextExamination || handleNextExaminationDate(),
      isAppeal: judgment?.isAppeal,
    },
  });

  const onSubmit = async (data: PhysicalProtectionJudgmentData) => {
    await store
      .dispatch(
        updateJudgment({
          ...data,
          examinationId,
          documentId,
        })
      )
      .then(() => void handleNavigateToPreviousPage());
  };

  useEffect(() => {
    if (!isFirst && !IsObjectEmpty(methods.formState.errors)) {
      dispatchNotification("error", I18n.t("Forms.snackbar.required"));
    }
  }, [methods.formState.errors, isFirst]);

  const judgmentOptions: MultiInputOptions[] = [
    {
      label: I18n.t("DocumentationManagement.judgment.physicalProtection.true"),
      value: true,
    },
    {
      label: I18n.t(
        "DocumentationManagement.judgment.physicalProtection.false"
      ),
      value: false,
    },
  ];

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <h2>{I18n.t("DocumentationManagement.judgment.judgmentNumberAppeal")}</h2>
            <div className={styles.fieldsContainer}>
              <FromInputCheckbox
                name="isAppeal"
                control={methods.control}
                label={I18n.t("DocumentationManagement.judgment.isAppealTitle")} />
            </div>
            <div className={styles.fieldsContainer}>
              <FormInputText
                control={methods.control}
                required={isFieldRequired(getSchema(judgment), "judgmentNo")}
                name="judgmentNo"
                label={I18n.t("Forms.fields.judgmentNumber")}
                error={!!methods.formState.errors.judgmentNo}
              />
              <FormInputText
                control={methods.control}
                name="year"
                inputMode="numeric"
                label={I18n.t("Forms.fields.year")}
                error={!!methods.formState.errors.year}
                maxInputLength={4}
              />
            </div>
          </div>
          <div className={styles.formContainer}>
            <FormDocumentExaminee requiredFields={examineeRequiredFields(getSchema(judgment))} initialData={judgment} />
          </div>
          <div className={styles.fieldJudgmentContainer}>
            <h2>{I18n.t("DocumentationManagement.judgment.statementThat")}*</h2>
            <FormInputMultiRadio
              name="isSuitable"
              control={methods.control}
              options={judgmentOptions}
              error={!!methods.formState.errors.isSuitable}
            />
          </div>
          <div className={styles.fieldsSectionContainer}>
            <div className={styles.fieldsContainer}>
              <FormDatePicker
                control={methods.control}
                name="nextExamination"
                label={I18n.t("Forms.fields.nextExamination")}
                error={!!methods.formState.errors.nextExamination}
              />
              <FormInputText
                control={methods.control}
                name="issueCity"
                label={I18n.t("Forms.fields.city")}
                error={!!methods.formState.errors.issueCity}
                required={isFieldRequired(getSchema(judgment), "issueCity")}
              />
              <FormDatePicker
                control={methods.control}
                name="issueDate"
                label={I18n.t("Forms.fields.documentDate")}
                error={!!methods.formState.errors.issueDate}
              />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <SecondaryButton
              text={I18n.t("Buttons.cancel")}
              event={handleNavigateToPreviousPage}
            />
            <PrimaryButton text={I18n.t("Buttons.save")} isSubmit />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default PhysicalProtectionJudgment;

import { RootState } from "../../store";

export const selectPsychologicalLabs = (state: RootState) =>
  state.psychologicalLabs.psychologicalLabs;

export const selectPsychologicalLabsLoading = (state: RootState) =>
  state.psychologicalLabs.loading;

export const selectPsychLabCUDSuccess = (state: RootState) =>
  state.psychologicalLabsCRUD.CUD.succeeded;

export const selectPsychLabCUDRefreshNeeded = (state: RootState) =>
  state.psychologicalLabsCRUD.CUD.refreshNeeded;

export const selectPsychLabLicCUDSuccess = (state: RootState) =>
  state.psychologicalLabsLicCRUD.CUD.succeeded;

export const selectSearchedPsychLabsList = (state: RootState) =>
  state.psychologicalLabs.searchedPsychLabs;

export const selectsSearchedPsychLabBarFilter = (state: RootState) =>
  state.psychologicalLabs.searchedFilter;

export const selectIsLoadingSearchedPsychLabsList = (state: RootState) =>
  state.psychologicalLabs.searchedLoading;

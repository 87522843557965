// extracted by mini-css-extract-plugin
export var h1Typography = "forms-module--h1-typography--aW7oc";
export var h2Typography = "forms-module--h2-typography--BoqU-";
export var h2TypographySecond = "forms-module--h2-typography-second--OfLPO";
export var h3Typography = "forms-module--h3-typography--Z06r9";
export var h3TypographyError = "forms-module--h3-typography-error--w0yP0";
export var h4Typography = "forms-module--h4-typography--SapF0";
export var pTypography = "forms-module--p-typography--pwq0g";
export var smallPTypography = "forms-module--small-p-typography--IZbfY";
export var sidebarTypography = "forms-module--sidebar-typography--FLpR0";
export var errorTypography = "forms-module--error-typography--Bs-1X";
export var captionTypography = "forms-module--caption-typography--c6pVU";
export var authMessageLabelTypography = "forms-module--auth-message-label-typography--rLqvC";
export var authMessageTypography = "forms-module--auth-message-typography--mglhR";
export var versionInfoTypography = "forms-module--version-info-typography--hz1Xu";
export var itemHidden = "forms-module--item-hidden--yQKy+";
export var ___gatsby = "forms-module--___gatsby--UHg9X";
export var gatsbyFocusWrapper = "forms-module--gatsby-focus-wrapper--p4Bez";
export var fieldsContainer = "forms-module--fields-container--Q8f9-";
export var fieldsSectionColContainer = "forms-module--fields-section-col-container--E+aCC";
export var fieldsSectionContainer = "forms-module--fields-section-container--r7Bl+";
export var fieldsContainerMobile = "forms-module--fields-container-mobile--QbAvD";
export var fieldsContainerDesktop = "forms-module--fields-container-desktop--ylThz";
export var filedsContainerSingleInput = "forms-module--fileds-container-singleInput--r4Dvh";
export var peselChbx = "forms-module--pesel-chbx--V3Oca";
export var radioBtnMobile = "forms-module--radio-btn-mobile--pyokD";
export var hideField = "forms-module--hide-field--E1YRQ";
export var buttonsContainer = "forms-module--buttons-container--WCtmY";
export var buttonsContainerMaxWide = "forms-module--buttons-container-max-wide--fClV5";
export var filtersContainer = "forms-module--filters-container--ljZD3";
export var textareaContainer = "forms-module--textarea-container--n1m6i";
export var formContainer = "forms-module--form-container--ivHIB";
export var formWideContainer = "forms-module--form-wide-container---ugat";
export var formMaxWideContainer = "forms-module--form-max-wide-container--LY+1N";
export var multicheckboxContainer = "forms-module--multicheckbox-container--8KIgu";
export var multicheckbox30Container = "forms-module--multicheckbox-30-container--fA26g";
export var input = "forms-module--input--bz3vr";
export var radioFieldContainer = "forms-module--radio-field-container--WmINS";